export { default as Accordion } from './Accordion';
export { default as AccordionSection } from './AccordionSection';
export { default as Block } from './Block';
export { default as CampaignCodeSection } from './CampaignCodeSection';
export { default as CommonQuestionsSection } from './CommonQuestionsSection';
export { default as EmailInput } from './EmailInput';
export { default as GoodToKnowSection } from './GoodToKnowSection';
export { default as ImportantDocumentsSection } from './ImportantDocumentsSection';
export { default as MaxWidthContainer } from './MaxWidthContainer/MaxWidthContainer';
export { default as PriceSuggestionSection } from './PriceSuggestionSection';
export { default as Text } from './Text';
export { default as TextAndImageSection } from './TextAndImageSection';
export { default as WhatIsIncludedSection } from './WhatIsIncludedSection';
export { default as WhenIsItSejfSection } from './WhenIsItSejfSection';
export { default as WhySejfaSection } from './WhySejfaSection';

export { default as OnboardingPeopleInput } from './OnboardingPeopleInput';
export { default as OnboardingTextField } from './OnboardingTextField';

export { default as InputMessage } from './InputMessage/InputMessage';
export { default as ProcessStep } from './ProcessStep';
export type { ProcessStepProps } from './ProcessStep';

export type { InputMessageProps } from './InputMessage/InputMessage';

export { default as Footer } from './Footer/Footer';
export { residentTextToNumber } from './OnboardingPeopleInput';
