import { createRequestCreatorForApi } from '@lib/api';

const quotesPaths = ['api/onboarding/pay-later'] as const;

const paths = [...quotesPaths] as const;

const INTERNAL_API = {
  baseUrl: '', // This is empty to create an absolute path
  paths
} as const;

export const createInternalRequest = createRequestCreatorForApi(INTERNAL_API);
