import Link from 'next/link';

import { Text } from '@features/onboarding-2';

import { LanguageSelector } from '../';
import { FooterColumnType, FooterLinkType } from '../../hooks/useFooterColumns';
import styles from './FooterColumn.module.css';

type Props = FooterColumnType;

const FooterColumn = ({ id, title, links }: Props) => {
  const renderLink = (link: FooterLinkType) => (
    <Link key={link.href} href={link.href} target="_blank">
      <Text className={styles.linkLabel}>{link.label}</Text>
    </Link>
  );

  const columnTitle = <Text type="heavy-small">{title}</Text>;

  return (
    <div key={title} className={styles.column}>
      {id === 'language' ? (
        <LanguageSelector />
      ) : (
        <>
          {columnTitle}
          {links.map(renderLink)}
        </>
      )}
    </div>
  );
};

export default FooterColumn;
