import Image, { ImageProps } from 'next/image';

import { cn } from '@lib/utils';

type BackgroundImageProps = {
  bgImg: Pick<ImageProps, 'src' | 'alt' | 'className'>;
};

const BackgroundImage = ({ bgImg }: BackgroundImageProps) => (
  <Image
    src={bgImg.src}
    alt={bgImg.alt}
    priority
    fill
    className={cn(
      'absolute z-[-1] h-full w-full object-cover object-bottom',
      bgImg.className
    )}
  />
);

export default BackgroundImage;
