import { useEffect } from 'react';

import { UseFormWatch, useForm } from 'react-hook-form';

import { usePriceSuggestionFormErrorsTranslation } from '@features/onboarding-2';

import { useUserDetails, useValidatePersonalNumber } from '../hooks';
import { OnboardingFormFields } from '../pages/Onboarding/components/OnboardingContainer';
import storage from '../storage';

function createInitialValues() {
  const { onboarding } = storage();

  return {
    personalNumber: onboarding?.personalNumber ?? '',
    age: onboarding?.age ?? '',
    address: onboarding?.address ?? '',
    postalCode: onboarding?.postalCode ?? '',
    numberOfResidents: '' // This data is not used to set the value of the input field, so it is kept empty even though there might be data in storage
  } as OnboardingFormFields;
}

export const useOnboardingForm = () => {
  const formMethods = useForm({
    defaultValues: createInitialValues()
  });

  const {
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    formState: { errors }
  } = formMethods;

  const personalNumber = useValidatePersonalNumber();
  const et = usePriceSuggestionFormErrorsTranslation();

  const {
    address,
    postalCode,
    age,
    isLoading: isPersonalNumberLoading
  } = useUserDetails({
    personalNumber,
    onError: () => {
      setError('personalNumber', { message: et('COULD_NOT_PREFILL_ADDRESS') });
    }
  });

  useWatchPersonalNumberChange(watch, personalNumber);

  useEffect(() => {
    if (address && postalCode && age && personalNumber.isValid) {
      setValue('age', age, { shouldDirty: true, shouldValidate: true });
      setValue('address', address, { shouldDirty: true, shouldValidate: true });
      setValue('postalCode', postalCode, {
        shouldDirty: true,
        shouldValidate: true
      });
    }
  }, [
    setValue,
    personalNumber.isValid,
    personalNumber.rawValue,
    age,
    address,
    postalCode
  ]);

  return {
    ...formMethods,
    prefillIsLoading: isPersonalNumberLoading && personalNumber.isValid,
    register,
    handleSubmit,
    errors
  };
};

const useWatchPersonalNumberChange = (
  watch: UseFormWatch<OnboardingFormFields>,
  personalNumber: { handleChange: (value: string) => void }
) => {
  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      if (value.personalNumber) {
        personalNumber.handleChange(value.personalNumber);
      }
    });

    return () => unsubscribe();
  }, [watch, personalNumber]);
};
