import { Button } from '@components/index';

import { QuoteResultSectionProps } from '../../';
import OnboardingRadioButton from '../../../../../../components/OnboardingRadioButton/OnboardingRadioButton';
import Text from '../../../../../../components/Text';
import { useSummaryPageTranslation } from '../../../../../../translation';
import QuoteResultDisplay from '../../../QuoteResultDisplay';
import styles from '../../QuoteResultSection.module.css';

interface QuoteResultContentProps extends QuoteResultSectionProps {}
const QuoteResultContent = ({
  isButtonDisabled,
  handlePayment,
  isPaymentLoading,
  register,
  renderExtraButton,

  ...quoteResultProps
}: QuoteResultContentProps) => {
  const t = useSummaryPageTranslation();
  const shouldDisableButton = !handlePayment || isButtonDisabled;

  return (
    <div className={styles.quoteResultContainer}>
      <h4 className={styles.heading}>{t('YOUR_PRICE_HEADING')}</h4>
      {quoteResultProps && <QuoteResultDisplay {...quoteResultProps} />}

      <div className={styles.paymentFrequency}>
        <Text type="heavy-medium">{t('PAYMENT_FREQUENCY_HEADER')}</Text>

        <div>
          <OnboardingRadioButton
            {...register('paymentPeriodId')}
            id="monthly"
            label={t('LABELS.PAYMENT_MONTHLY')}
            value="2"
          />
          <OnboardingRadioButton
            {...register('paymentPeriodId')}
            id="yearly"
            label={t('LABELS.PAYMENT_YEARLY')}
            value="1"
            extraPaymentIcons={['apple-pay', 'swish']}
          />
        </div>

        <Button
          isLoading={isPaymentLoading}
          onClick={handlePayment}
          disabled={shouldDisableButton}
          variant="fourth"
          className={styles.getInsuranceButton}
        >
          {t('GET_INSURANCE_BUTTON')}
        </Button>

        {renderExtraButton && renderExtraButton(!!shouldDisableButton)}
      </div>
    </div>
  );
};
export default QuoteResultContent;
