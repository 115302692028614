import { useEffect, useMemo, useReducer } from 'react';

import { UseFormRegister } from 'react-hook-form';

import { IconButton } from '@components/index';

import styles from './OnboardingPeopleInput.module.css';
import {
  reducer,
  initializeStates,
  DECREMENT,
  INCREMENT,
  MIN_RESIDENTS,
  MAX_RESIDENTS
} from './reducer';
import {
  usePriceSuggestionLabelsTranslation,
  usePriceSuggestionResidentsStates
} from '../../translation';
import OnboardingTextField from '../OnboardingTextField/OnboardingTextField';
import { OnboardingFormFields } from '../../pages/Onboarding/components/OnboardingContainer/OnboardingContainer';
import storage from '../../storage';

type OnboardingPeopleInputProps = {
  onChange?: (value: string) => void;
  register?: UseFormRegister<OnboardingFormFields>;
};

const OnboardingPeopleInput = ({
  onChange,
  register
}: OnboardingPeopleInputProps) => {
  const t = usePriceSuggestionLabelsTranslation();
  const rt = usePriceSuggestionResidentsStates();
  const { onboarding } = storage();
  const residentAlternatives = useMemo(() => initializeStates(rt), [rt]);
  const [currentAmount, dispatch] = useReducer(
    reducer,
    onboarding?.residents ?? 1 // TODO: Ideally this default value would be sent down via props. Not sure how this would work with useForm and numberOfResidents being a string
  );

  useEffect(() => {
    if (!onChange) return;

    onChange(residentAlternatives[currentAmount]);
  }, [currentAmount, onChange, residentAlternatives]);

  const formProps = register ? register('numberOfResidents') : {};

  return (
    <div className={styles.peopleInput}>
      <OnboardingTextField
        {...formProps}
        id="people-input"
        label={t('RESIDENTS')}
        readOnly
      />
      <div className={styles.actions}>
        <IconButton
          name="remove"
          variant="tertiary"
          className={styles.actionButton}
          onClick={() => dispatch({ type: DECREMENT })}
          disabled={currentAmount === MIN_RESIDENTS}
          description="Remove resident"
        />
        <IconButton
          name="add"
          variant="tertiary"
          className={styles.actionButton}
          onClick={() => dispatch({ type: INCREMENT })}
          disabled={currentAmount === MAX_RESIDENTS}
          description="Add resident"
        />
      </div>
    </div>
  );
};

export default OnboardingPeopleInput;
