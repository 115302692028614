import * as date from 'date-fns';
import { enUS, sv } from 'date-fns/locale';

const formatRelativeLocales = {
  en: {
    today: "'Today', dd MMM yyyy",
    tomorrow: "'Tomorrow', dd MMM yyyy",
    other: 'dd MMM yyyy'
  },
  sv: {
    today: "'Idag', dd MMM yyyy",
    tomorrow: "'Imorgon', dd MMM yyyy",
    other: 'dd MMM yyyy'
  }
};

const createFormatRelative = (language: 'en' | 'sv') => {
  return (token: keyof typeof formatRelativeLocales.en) =>
    formatRelativeLocales[language][token];
};

const locales = {
  en: {
    ...enUS,
    formatRelative: createFormatRelative('en')
  },
  sv: {
    ...sv,
    formatRelative: createFormatRelative('sv')
  }
};

export const setLocale = (language: 'en' | 'sv') => {
  date.setDefaultOptions({ locale: locales[language] });
};

setLocale('sv');

export { date };
