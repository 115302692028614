import { PropsWithChildren } from 'react';

import Link from 'next/link';

import { Text } from '@features/onboarding-2';

import styles from './NavLink.module.css';

type Props = {
  href: string;
};

const NavLink = ({ href, children }: PropsWithChildren<Props>) => {
  return (
    <Link href={href} className={styles.link}>
      <Text type="heavy-small">{children}</Text>
    </Link>
  );
};

export default NavLink;
