import WhatIsIncludedImg from '@assets/images/what-is-included-img.webp';

import { usePriceSuggestionPageTranslation } from '../../translation';
import TextAndImageSection from '../TextAndImageSection';

const WhatIsIncludedSection = () => {
  const t = usePriceSuggestionPageTranslation();
  return (
    <TextAndImageSection
      imageProps={{
        src: WhatIsIncludedImg,
        alt: 'Pizza and a wine glass'
      }}
      imagePosition="right"
      title={t('WHAT_IS_INCLUDED.TITLE')}
      description={t('WHAT_IS_INCLUDED.TEXT')}
      list={[
        t('WHAT_IS_INCLUDED.LIST.ITEM_1'),
        t('WHAT_IS_INCLUDED.LIST.ITEM_2'),
        t('WHAT_IS_INCLUDED.LIST.ITEM_3'),
        t('WHAT_IS_INCLUDED.LIST.ITEM_4')
      ]}
    />
  );
};

export default WhatIsIncludedSection;
