import { Button, type ButtonProps } from '@components/index';

import { useSummaryPageTranslation } from '../../../../translation';

interface PayLaterButtonProps extends ButtonProps {}

const PayLaterButton = ({ onClick, disabled }: PayLaterButtonProps) => {
  const t = useSummaryPageTranslation();

  return (
    <Button variant="tertiary" onClick={onClick} disabled={disabled}>
      {t('PAY_LATER_BUTTON')}
    </Button>
  );
};

export default PayLaterButton;
