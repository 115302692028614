import { useEffect, useState } from 'react';

import { GetCampaignByCodeResponse, useGetCampaignByCode } from '@core/api';
import { useMessaging } from '@core/messaging';
import { useTranslation } from '@core/translation';
import { logger } from '@lib/logger';

const useCampaignDiscount = () => {
  const [campaignCode, setCampaignCode] = useState<string>();
  const [discountData, setDiscountData] = useState<GetCampaignByCodeResponse>();
  const { refetch, error } = useGetCampaignByCode(campaignCode!)({
    options: {
      enabled: false,
      onError: (error) => {
        logger.error('Error fetching discount code', error);
        setCampaignCode(undefined);
        addMessage({
          message: tError('INVALID_DISCOUNT_CODE'),
          payload: error
        });
      }
    }
  });

  const tError = useTranslation('ERROR');
  const { addMessage } = useMessaging();

  useEffect(() => {
    if (campaignCode) {
      (async () => {
        const { data } = await refetch();
        setDiscountData(data);
      })();
    }
  }, [campaignCode, refetch]);

  const getDiscountCode = async (code?: string) => {
    setCampaignCode(code);
  };

  const resetCampaignDiscountState = () => {
    setDiscountData(undefined);
    setCampaignCode(undefined);
  };

  return {
    discountCode: discountData?.discountCode,
    campaignCode: discountData?.campaignCode,
    resetCampaignDiscountState,
    getDiscountCode,
    error
  };
};

export default useCampaignDiscount;
