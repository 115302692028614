import { forwardRef } from 'react';

import { Text } from '@features/onboarding-2';

import styles from './OnboardingTextFieldSimple.module.css';
import {
  OnboardingTextInput,
  OnboardingTextInputProps
} from '../OnboardingTextField'; // TODO: export from onboarding-2

type OnboardingTextFieldSimpleProps = OnboardingTextInputProps & {
  error?: string;
};

export const OnboardingTextFieldSimple = forwardRef<
  HTMLInputElement,
  OnboardingTextFieldSimpleProps
>(({ id, label, className, error, isLoading, ...props }, ref) => {
  return (
    <div>
      <OnboardingTextInput
        id={id}
        label={label}
        className={className}
        ref={ref}
        isLoading={isLoading}
        {...props}
      />
      {error && <Text className={styles.errorMessage}>{error}</Text>}
    </div>
  );
});

OnboardingTextFieldSimple.displayName = 'OnboardingTextFieldSimple';

export default OnboardingTextFieldSimple;
