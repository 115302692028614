interface PriceBlockProps {
  price: number;
}

import styles from '../../PriceSuggestionSection.module.css';

const PriceBlock = ({ price }: PriceBlockProps) => (
  <div className={styles.priceBlock}>
    <h2>{price}</h2>
    <h4>kr</h4>
  </div>
);

export default PriceBlock;
