import { useState } from 'react';

import {
  InputMessage,
  OnboardingPeopleInput,
  usePriceSuggestionFormErrorsTranslation
} from '@features/onboarding-2';

import ResidencyStatusDropdown from './components/ResidencyStatusDropdown';
import ResidencyTypeDropdown from './components/ResidencyTypeDropdown';
import { HomeInfoFieldsProps, Option, ResidencyConfig } from './types';

const RESIDENCY_STATUS_RULES: Record<string, ResidencyConfig> = {
  owning_villa: { isRenting: false, isSendDisabled: true },
  renting: { isRenting: true, isSendDisabled: false },
  default: { isRenting: true, isSendDisabled: false }
};

export const useResidencyStatus = (
  setIsSendDisabled: (disabled: boolean) => void
) => {
  const [residencyType, setResidencyType] = useState('apartment');
  const [isRenting, setIsRenting] = useState(true);

  const handleResidencyStatusChange = (residencyStatus: Option) => {
    const residencyStatusKey =
      residencyStatus.value === 'owning' && residencyType === 'villa'
        ? 'owning_villa'
        : residencyStatus.value;

    const residencyRule =
      RESIDENCY_STATUS_RULES[residencyStatusKey] ||
      RESIDENCY_STATUS_RULES.default;
    setIsRenting(residencyRule.isRenting);
    setIsSendDisabled(residencyRule.isSendDisabled);
  };

  return {
    residencyType,
    setResidencyType,
    isRenting,
    handleResidencyStatusChange
  };
};

const HomeInfoFields = ({
  setValue,
  register,
  setIsSendDisabled
}: HomeInfoFieldsProps) => {
  const et = usePriceSuggestionFormErrorsTranslation();
  const {
    residencyType,
    setResidencyType,
    isRenting,
    handleResidencyStatusChange
  } = useResidencyStatus(setIsSendDisabled);

  return (
    <>
      <OnboardingPeopleInput
        onChange={(value) => setValue('numberOfResidents', value)}
        register={register}
      />
      <ResidencyTypeDropdown onResidencyTypeChange={setResidencyType} />
      {residencyType === 'villa' && (
        <ResidencyStatusDropdown onStatusChange={handleResidencyStatusChange} />
      )}
      {!isRenting && <InputMessage title={et('OWNING_HOUSE')} />}
    </>
  );
};

export default HomeInfoFields;
