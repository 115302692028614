import { useRouter } from 'next/router';

import { Button } from '@components/Button/Button';
import { useCampaignCodeFromUrl } from '@core/discount';
import { useTranslation } from '@core/translation';
import { DISCOUNT_CODE as ACCORD_DISCOUNT_CODE } from '@features/accord';
import { useLandingPageNavigation } from '@features/landing-page';
import { DISCOUNT_CODES_BY_DISCOUNT } from '@features/safety-by-cilia';
import { cn } from '@lib/utils';

import styles from './SeePriceButton.module.css';

const SeePriceButton = () => {
  const lt = useTranslation('LANDING_PAGE');
  const { pathname } = useRouter();
  const { navigateToOnboarding } = useLandingPageNavigation();
  const { campaignCodeFromURL } = useCampaignCodeFromUrl();

  const isInOnboarding = pathname.includes('/onboarding');
  const isSafetyByCiliaPage =
    pathname.includes('/safety-by-cilia') && !pathname.includes('/success');
  const isAccordPage = pathname.includes('/accord');

  const handleSeePriceClick = () => {
    if (isSafetyByCiliaPage) {
      navigateToOnboarding(DISCOUNT_CODES_BY_DISCOUNT[40]);
    } else if (isAccordPage) {
      navigateToOnboarding(ACCORD_DISCOUNT_CODE);
    } else if (campaignCodeFromURL) {
      navigateToOnboarding(campaignCodeFromURL);
    } else {
      navigateToOnboarding();
    }
  };

  return (
    <Button
      onClick={handleSeePriceClick}
      variant="primary"
      hugged
      className={cn(styles.button, isInOnboarding && styles.hidden)}
    >
      {lt('CALL_TO_ACTION')}
    </Button>
  );
};

export default SeePriceButton;
