import WhySejfaImg from '@assets/images/why-sejfa-img.webp';

import { usePriceSuggestionPageTranslation } from '../../translation';
import TextAndImageSection from '../TextAndImageSection';
const WhySejfaSection = () => {
  const t = usePriceSuggestionPageTranslation();
  return (
    <TextAndImageSection
      imageProps={{
        src: WhySejfaImg,
        alt: 'A person lying in bed surrounded by colorful balloons, covering their face with a large teal balloon.'
      }}
      title={t('WHY_SEJFA.QUESTION')}
      description={t('WHY_SEJFA.ANSWER')}
    />
  );
};

export default WhySejfaSection;
