import React, { ReactNode } from 'react';

import ActivityIndicator from '@components/ActivityIndicator/ActivityIndicator';
import Icon, { IconType } from '@components/Icon/Icon';
import { cn } from '@lib/utils';

import styles from './Button.module.css';

export type ButtonProps = {
  children?: ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'fourth';
  hugged?: boolean;
  icon?: IconType;
  iconSize?: number;
  iconClassName?: string;
  size?: 'big' | 'small';
} & React.ComponentProps<'button'>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      children,
      onClick,
      isLoading,
      hugged,
      variant = 'primary',
      icon,
      className,
      iconClassName,
      size = 'small',
      iconSize,
      ...rest
    } = props;

    const classes = cn(
      styles.button,
      styles[variant],
      hugged && styles.buttonHugged,
      styles[size],
      className
    );

    return (
      <button onClick={onClick} className={classes} ref={ref} {...rest}>
        {children}
        {isLoading && (
          <span className={styles.marginLeft}>
            <ActivityIndicator />
          </span>
        )}
        {icon && !isLoading && (
          <Icon
            name={icon}
            className={cn(styles.marginLeft, iconClassName)}
            size={iconSize}
          />
        )}
      </button>
    );
  }
);
