import { useRouter } from 'next/router';

import ActionModal from '@components/ActionModal/ActionModal';

import { useSummaryPageTranslation } from '../../../../translation';

interface ChangeDetailsModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const ChangeDetailsModal = ({
  isOpen,
  closeModal
}: ChangeDetailsModalProps) => {
  const t = useSummaryPageTranslation();
  const { push } = useRouter();

  return (
    isOpen && (
      <ActionModal
        onPrimaryClick={() => {
          push('/onboarding-2');
        }}
        onSecondaryClick={() => closeModal()}
        title={t('CHANGE_DETAILS_MODAL.TITLE')}
        description={t('CHANGE_DETAILS_MODAL.DESCRIPTION')}
        primaryButtonText={t('CHANGE_DETAILS_MODAL.PRIMARY_BUTTON')}
      />
    )
  );
};

export default ChangeDetailsModal;
