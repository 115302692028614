import { PropsWithChildren } from 'react';

import Head from 'next/head';

import { Block } from '@components/index';

const SubpageLayout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Head>
        <title>Sejfa</title>
        <meta name="robots" content="noindex" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Block className="no-scrollbar overflow-auto bg-black p-0 pb-0 pr-0">
        {children}
      </Block>
    </>
  );
};

export default SubpageLayout;
