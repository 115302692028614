export type PaymentPeriodId = '1' | '2';

export type FormData = {
  postalCode: number;
  residents: number;
  address: string;
  monthlyPremium: number;
  paymentPeriodId: PaymentPeriodId;
  numberOfResidents?: string;
  policyStartDate: string;
};

const getDefaultFormData = (): FormData => ({
  postalCode: 0,
  residents: 0,
  address: '',
  monthlyPremium: 0,
  paymentPeriodId: '2',
  policyStartDate: new Date().toISOString()
});

export { getDefaultFormData };
