import { Icon } from '@components/index';
import { cn } from '@lib/utils';

import {
  type PaymentMethodIcon,
  PaymentMethodIcon as PaymentIcon
} from '../PaymentMethodIcon';
import DynamicPaymentIcon from '../DynamicPaymentIcon';

interface PaymentIconsProps {
  icons?: PaymentMethodIcon['type'][];
  className?: string;
}

const PaymentIcons = ({ icons, className }: PaymentIconsProps) => (
  <span className={cn(className)}>
    {icons?.length &&
      icons.map((icon) => <DynamicPaymentIcon key={icon} icon={icon} />)}
    <PaymentIcon>
      <Icon name="visa" className="h-4 w-8" />
      <Icon name="mastercard" className="h-4 w-8" />
    </PaymentIcon>
  </span>
);

export default PaymentIcons;
