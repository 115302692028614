import PersonOutline from '@assets/icons/PersonOutline';
import { Button } from '@components/Button/Button';
import { useLandingPageNavigation } from '@features/landing-page';

import styles from './ProfileButton.module.css';

const ProfileButton = () => {
  const { navigateToLogin } = useLandingPageNavigation();
  return (
    <Button
      onClick={navigateToLogin}
      variant="primary"
      hugged
      className={styles.profileButton}
    >
      <PersonOutline />
    </Button>
  );
};

export default ProfileButton;
