import client from './client';
import { CONTENT_ENTRY, ContentType } from './types';

export const findSlugs = async (contentTypeKey: keyof ContentType) => {
  const entries = await client.getEntries({
    content_type: CONTENT_ENTRY[contentTypeKey],
    select: ['fields.slug']
  });

  const slugs = entries.items.map((entry) => entry.fields.slug as string);

  return slugs;
};

export const findEntryBySlug = async (
  slug: string | string[],
  contentTypeKey: keyof ContentType
) => {
  const entries = await client.getEntries({
    content_type: CONTENT_ENTRY[contentTypeKey],
    'fields.slug': slug
  });

  if (entries.items.length === 0) {
    return null;
  }

  return entries.items[0];
};
