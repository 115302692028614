import { PropsWithChildren } from 'react';

import { cn } from '@lib/utils';

import styles from './Block.module.css';

type BlockProps = PropsWithChildren & {
  className?: string;
  variant?: 'primary' | 'fourth';
  blockInnerClassName?: string;
};

const Block = ({
  children,
  className,
  blockInnerClassName,
  variant = 'primary'
}: BlockProps) => {
  return (
    <div className={cn(styles.block, className, styles[variant])}>
      <div className={cn(styles.blockInner, blockInnerClassName)}>
        {children}
      </div>
    </div>
  );
};

export default Block;
