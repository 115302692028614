import { Button } from '@components/index';
import { MaxWidthContainer } from '@features/onboarding-2';

import { YourPrice } from '../';
import Datepicker from '../../../../pages/PriceSuggestion/components/Datepicker';
import { usePriceSuggestionPageTranslation } from '../../../../translation';
import Block from '../../../Block';
import Text from '../../../Text';
import styles from '../../PriceSuggestionSection.module.css';

interface PriceSuggestionBlockProps {
  monthlyPremium: number;
  discountMonthlyPremium?: number;
  selectedStartDate: Date;
  onDateChange: (date: Date) => void;
  onAuthClick: () => void;
  isLoading: boolean;
}

const PriceSuggestionBlock = ({
  monthlyPremium,
  discountMonthlyPremium,
  selectedStartDate,
  onDateChange,
  onAuthClick,
  isLoading
}: PriceSuggestionBlockProps) => {
  const t = usePriceSuggestionPageTranslation();

  return (
    <Block variant="fourth" className={styles.priceSuggestionBlock}>
      <MaxWidthContainer>
        <div className={styles.priceSuggestionBlock}>
          <div className={styles.headingContainer}>
            <h4>{t('HEADING')}</h4>
            <Text type="normal-tiny">{t('DESCRIPTION')}</Text>
          </div>
          <YourPrice
            price={monthlyPremium}
            discountMonthlyPremium={discountMonthlyPremium}
          />
          <div className={styles.actionsContainer}>
            <Datepicker date={selectedStartDate} onDateChange={onDateChange} />
            <Button
              variant="fourth"
              onClick={onAuthClick}
              isLoading={isLoading}
              disabled={isLoading}
              icon="bank-id"
              iconClassName={styles.bankIdIcon}
              className={styles.button}
            >
              {t('CONTINUE')}
            </Button>
          </div>
        </div>
      </MaxWidthContainer>
    </Block>
  );
};

export default PriceSuggestionBlock;
