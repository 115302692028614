import { useState } from 'react';

import {
  ImportantDocumentsSection,
  storage,
  useOnboarding2
} from '@features/onboarding-2';
import { NextPageWithLayout } from '@pages/_app';
import { useToggle } from '@lib/utils';

import { useSummaryPageTranslation } from '../../translation';
import ConditionalPayLaterButton from './components/ConditionalPayLaterButton';
import FormSection from './components/FormSection/';
import QuoteResultSection from './components/OuoteResultSection/QuoteResultSection';
import { getDefaultFormData } from './formData';
import { getQuoteResultProps } from './getQuoteResultProps';
import useCalculateQuoteRequest from './hooks/useCalculateQuoteRequest';
import useCreatePaymentRequest from './hooks/useCreatePaymentRequest';
import useFormSetup from './hooks/useFormSetup';
import useIsClient from './hooks/useIsClient';
import { getUnit } from './paymentCalculations';
import styles from './Summary.module.css';
import PayLaterModal from './components/PayLaterModal';
import ChangeDetailsModal from './components/ChangeDetailsModal';

const Summary: NextPageWithLayout = () => {
  const isOnboarding2On = useOnboarding2();

  const t = useSummaryPageTranslation();
  const [isPayLaterModalOpen, openPayLaterModal, closePayLaterModal] =
    useToggle();
  const [
    isChangeDetaiLsModalOpen,
    openChangeDetailsModal,
    closeChangeDetailsModal
  ] = useToggle();

  const isClient = useIsClient();

  const { register, getValues, watch } = useFormSetup({
    ...getDefaultFormData(),
    ...storage().onboarding
  });

  const { response, isLoading: isQuoteLoading } = useCalculateQuoteRequest();

  const { pay, isLoading: isPaymentLoading } = useCreatePaymentRequest(
    getValues()
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const paymentPeriodId = watch('paymentPeriodId');

  const quoteResultProps = getQuoteResultProps(response, paymentPeriodId);

  if (!isClient) {
    return null; // This is needed due to hydration errors because we're using localStorage to prefill data.
  }

  if (!isOnboarding2On) return null;

  return (
    <div className={styles.summaryPage}>
      <FormSection
        formData={getValues()}
        setIsButtonDisabled={setIsButtonDisabled}
        onChangeDetailsClick={openChangeDetailsModal}
      />

      <QuoteResultSection
        {...quoteResultProps}
        unit={getUnit(paymentPeriodId, {
          monthlyText: t('PRICE_PER_MONTH'),
          yearlyText: t('PRICE_PER_YEAR')
        })}
        paymentPeriodId={paymentPeriodId}
        isButtonDisabled={isButtonDisabled}
        handlePayment={pay}
        register={register}
        isQuoteLoading={isQuoteLoading}
        renderExtraButton={() => (
          <ConditionalPayLaterButton
            isDisabled={isButtonDisabled}
            paymentPeriodId={paymentPeriodId}
            onClick={openPayLaterModal}
          />
        )}
        isPaymentLoading={isPaymentLoading}
      />

      <ImportantDocumentsSection />

      <PayLaterModal
        isOpen={isPayLaterModalOpen}
        closeModal={closePayLaterModal}
        formData={{
          postalCode: Number(getValues('postalCode')),
          address: getValues('address'),
          numberOfResidents: Number(getValues('residents')),
          paymentPeriodId: Number(getValues('paymentPeriodId'))
        }}
      />

      <ChangeDetailsModal
        isOpen={!isPayLaterModalOpen && isChangeDetaiLsModalOpen}
        closeModal={closeChangeDetailsModal}
      />
    </div>
  );
};

export default Summary;
