import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { IconButton } from '@components/index';
import { date as dateLib } from '@lib/date';
import { cn } from '@lib/utils';

import Text from '../../../../../components/Text';
import styles from './DatepickerHeader.module.css';

const DatepickerHeader = ({
  increaseMonth,
  decreaseMonth,
  monthDate,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}: ReactDatePickerCustomHeaderProps) => (
  <div className={styles.datepickerHeader}>
    <div className={styles.datepickerControls}>
      <IconButton
        name="arrow-down"
        disabled={prevMonthButtonDisabled}
        className={cn(styles.datepickerButton, styles.datepickerButtonPrev)}
        onClick={decreaseMonth}
      />
      <Text type="heavy-small">{dateLib.format(monthDate, 'MMMM yyyy')}</Text>
      <IconButton
        name="arrow-down"
        className={cn(styles.datepickerButton, styles.datepickerButtonNext)}
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      />
    </div>
  </div>
);

export default DatepickerHeader;
