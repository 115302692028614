import { useCallback, useRef, useState } from 'react';

import axios from 'axios';

import { useBankId } from '@core/auth';
import { useMessaging } from '@core/messaging';
import { useTranslation } from '@core/translation';
import { axiosWithAuth } from '@lib/api';
import { AuthResponse } from '@lib/auth';
import { logger } from '@lib/logger';
import { useToggle } from '@lib/utils';

const useLogin = () => {
  const { addMessage } = useMessaging();
  const tError = useTranslation('ERROR');
  const { state: isLoading, open: showLoader, close: hideLoader } = useToggle();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const isAuthInitiated = useRef(false);

  const handleValidateTokenSuccess = useCallback(
    (accessToken?: string) => {
      if (accessToken) {
        setIsAuthenticated(true);
        hideLoader();
      }
    },
    [hideLoader]
  );

  const authCallback = useCallback(
    async (response: AuthResponse) => {
      if (isAuthInitiated.current) return;
      isAuthInitiated.current = true;
      if (response.type === 'authenticated' && response.success) {
        const { success, type, token, provider, transactionId } = response;

        try {
          const response = await axiosWithAuth.post('/api/auth/login', {
            success,
            type,
            identityToken: token,
            provider,
            transactionId
          });

          const accessToken = response.data.result.accessToken;

          if (!accessToken) {
            throw 'Access token not found';
          }

          handleValidateTokenSuccess(accessToken);
        } catch (error) {
          logger.error('Error in auth callback', error);
          isAuthInitiated.current = false;
          addMessage({
            message: tError('AUTH.VALIDATE_TOKEN_FAILED'),
            payload: error
          });
          hideLoader();
        }
        return;
      }

      setIsAuthenticated(false);
    },
    [addMessage, handleValidateTokenSuccess, hideLoader, tError]
  );

  const onError = useCallback(
    (response: AuthResponse) => {
      const { type } = response;

      let errorMessage = '';

      if (type === 'cancelled') {
        errorMessage = tError('AUTH.USER_CANCELLED');
        hideLoader();
      }

      if (type === 'error') {
        errorMessage = tError('UNEXPECTED_ERROR');
        hideLoader();
      }

      if (type === 'failed') {
        errorMessage = tError('AUTH.FAILED');
        hideLoader();
      }

      logger.error(`useLogin error with type ${type}. Response: `, response);

      if (errorMessage) {
        addMessage({
          message: errorMessage
        });
      }
    },
    [tError, addMessage, hideLoader]
  );

  const { authenticateWithBankId } = useBankId(authCallback, onError);

  const authenticate = useCallback(async () => {
    showLoader();
    try {
      const response = await axios.post('/api/auth/create-token');

      const { data } = response.data;
      const error = !data;

      if (error) {
        addMessage({
          message: tError('UNEXPECTED_ERROR'),
          payload: error
        });
        logger.error('Authenticate error: ', error);
        hideLoader();
        return;
      }

      if (data?.result) {
        authenticateWithBankId(data.result.authorizationKey);
      }
    } catch (error) {
      addMessage({
        message: tError('UNEXPECTED_ERROR'),
        payload: error
      });
      logger.error('Authenticate error: ', error);
      hideLoader();
    }
  }, [addMessage, authenticateWithBankId, hideLoader, showLoader, tError]);

  return {
    login: authenticate,
    isAuthenticated,
    isLoading
  };
};

export default useLogin;
