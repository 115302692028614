import { NextPageWithLayout } from '@pages/_app';

import { OpenAppPage } from '../components/OpenAppPage';
import { useAppLinks } from '../hooks/useAppLinks';

const redirectToUrl = (url: string) => {
  window.location.href = url;
};

export const OpenProductionAppPage: NextPageWithLayout = () => {
  const { appLink, storeLink } = useAppLinks({ environment: 'production' });

  return (
    <OpenAppPage
      openApp={() => redirectToUrl(appLink)}
      openStore={() => redirectToUrl(storeLink)}
    />
  );
};
