import { usePriceSuggestionPageTranslation } from '../../../../translation';
import Text from '../../../Text';
import styles from '../../PriceSuggestionSection.module.css';
import PriceBlock from '../PriceBlock';
interface YourPriceProps {
  price: number;
  discountMonthlyPremium?: number;
}

const YourPrice = ({ price, discountMonthlyPremium = 0 }: YourPriceProps) => {
  const t = usePriceSuggestionPageTranslation();
  const discountPrice = discountMonthlyPremium + price;
  const shouldShowDiscount = discountMonthlyPremium > 0;

  return (
    <div className={styles.yourPrice}>
      {shouldShowDiscount ? (
        <>
          <Text type="normal-big">{t('YOUR_PRICE_WITH_DISCOUNT')}</Text>
          <Text type="normal-small" className="line-through">
            {discountPrice.toFixed(0) + 'kr'}
          </Text>
        </>
      ) : (
        <Text type="normal-big">{t('YOUR_PRICE')}</Text>
      )}
      <PriceBlock price={price} />
      <Text type="normal-big">{t('PAYMENT_FREQUENCY')}</Text>
    </div>
  );
};

export default YourPrice;
