import { FieldErrors, UseFormRegister } from 'react-hook-form';

import {
  ProcessStep,
  ProcessStepProps,
  usePriceSuggestionLabelsTranslation
} from '@features/onboarding-2';

import { PersonalInfoFields } from '../';
import { OnboardingFormFields } from '../../../OnboardingContainer';
type Props = {
  register: UseFormRegister<OnboardingFormFields>;
  validationStateStep: ProcessStepProps['state'];
  errors?: FieldErrors<OnboardingFormFields>;
  prefillIsLoading?: boolean;
};

const AboutYouStep = ({
  register,
  validationStateStep,
  errors,
  prefillIsLoading
}: Props) => {
  const t = usePriceSuggestionLabelsTranslation();
  return (
    <ProcessStep
      step={1}
      title={t('PROCESS_STEP_ABOUT_YOU')}
      description={t('PROCESS_STEP_ABOUT_YOU_DESCRIPTION')}
      state={validationStateStep}
    >
      <PersonalInfoFields
        register={register}
        errors={errors}
        prefillIsLoading={prefillIsLoading}
      />
    </ProcessStep>
  );
};

export default AboutYouStep;
