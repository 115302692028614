import { ImageProps } from 'next/image';

import { cn } from '@lib/utils';

import BackgroundImage from './components/BackgroundImage';
import CallToAction from './components/CallToAction';
import LogoSection from './components/LogoSection';
import Overlay from './components/Overlay';

type Props = {
  bgImg: Pick<ImageProps, 'src' | 'alt' | 'className'>;
  campaignImg?: Pick<ImageProps, 'src' | 'alt'>;
  title: string | React.ReactNode;
  titleClassName?: string;
  logoClassName?: string;
  logoContainerClassName?: string;
  ctaContainerClassName?: string;
  middleImgClassName?: string;
  containerClassName?: string;
  cta?: () => void;
  ctaDescription?: string;
  ctaDescriptionClassName?: string;
  ctaButtonTitle?: string;
};

const CampaignPageHero = ({
  bgImg,
  campaignImg,
  title,
  titleClassName,
  logoClassName,
  ctaContainerClassName,
  middleImgClassName,
  containerClassName,
  ctaDescription,
  ctaDescriptionClassName,
  ctaButtonTitle,
  cta
}: Props) => (
  <Overlay>
    <div
      className={cn(
        'relative h-[calc(100dvh-4rem)] overflow-hidden rounded-2xl md:h-[76vh] md:max-h-[76vh] md:rounded-[32px]',
        containerClassName
      )}
    >
      <div className="relative flex h-full items-center justify-center overflow-hidden rounded-2xl md:rounded-[32px]">
        <BackgroundImage bgImg={bgImg} />
        <div className="flex h-full flex-col items-center justify-center p-4 md:h-auto md:justify-center">
          {!!campaignImg?.src && (
            <LogoSection
              campaignImg={campaignImg}
              logoClassName={logoClassName}
              middleImgClassName={middleImgClassName}
            />
          )}
          <CallToAction
            title={title}
            titleClassName={titleClassName}
            cta={cta}
            buttonTitle={ctaButtonTitle}
            description={ctaDescription}
            containerClassName={ctaContainerClassName}
            descriptionClassName={ctaDescriptionClassName}
          />
        </div>
      </div>
    </div>
  </Overlay>
);

export default CampaignPageHero;
