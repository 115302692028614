import { SejfaLogo } from '@components/index';
import { Block, Text } from '@features/onboarding-2';

import { FooterColumn } from './components';
import styles from './Footer.module.css';
import useFooterColumns, { FooterColumnType } from './hooks/useFooterColumns';

const Footer = () => {
  const columns = useFooterColumns();

  const renderColumn = (column: FooterColumnType) => (
    <FooterColumn key={column.id} {...column} />
  );

  return (
    <footer>
      <Block className={styles.block} blockInnerClassName={styles.grid}>
        {columns.map(renderColumn)}
      </Block>
      <div className={styles.org}>
        <Text type="normal-tiny">© 2025 Supersejf AB</Text>
        <SejfaLogo className={styles.sejfaLogo} />
        <Text type="normal-tiny">Org.nr: 559378-1676</Text>
      </div>
    </footer>
  );
};

export default Footer;
