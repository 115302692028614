import { forwardRef } from 'react';

import { Icon } from '@components/index';

import { usePriceSuggestionPageTranslation } from '../../../../../../translation';
import styles from './StartDateSelector.module.css';

type Props = {
  selectedDate: string;
};

const StartDateSelector = forwardRef<HTMLButtonElement, Props>(
  ({ selectedDate, ...rest }, ref) => {
    const t = usePriceSuggestionPageTranslation();

    const startDate = (
      <div className={styles.startDateContainer}>
        <p>{t('START_DATE')}</p>
        <p className={styles.selectedDate}>{selectedDate}</p>
      </div>
    );

    const calendarIcon = (
      <div>
        <Icon name="calendar" className={styles.calendarIcon} />
      </div>
    );

    const content = (
      <div className={styles.dateSelectorContent}>
        {startDate}
        {calendarIcon}
      </div>
    );

    return (
      <button ref={ref} {...rest} className={styles.dateSelector}>
        {content}
      </button>
    );
  }
);

export default StartDateSelector;
