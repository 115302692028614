import { Block } from '../../components';
import { usePriceSuggestionPageTranslation } from '../../translation';
import styles from './GoodToKnowSection.module.css';

const GoodToKnowSection = () => {
  const t = usePriceSuggestionPageTranslation();

  const data = [
    {
      title: t('GOOD_TO_KNOW.BLOCK_1.TITLE'),
      price: '1 000 000 kr',
      text: t('GOOD_TO_KNOW.BLOCK_1.TEXT')
    },
    {
      title: t('GOOD_TO_KNOW.BLOCK_2.TITLE'),
      price: '1 500 kr',
      text: t('GOOD_TO_KNOW.BLOCK_2.TEXT')
    },
    {
      title: t('GOOD_TO_KNOW.BLOCK_3.TITLE'),
      price: '45 dagar',
      text: t('GOOD_TO_KNOW.BLOCK_3.TEXT')
    }
  ];

  const infoBlocks = (
    <div className={styles.infoBlockContainer}>
      {data.map((item, index) => (
        <div key={index} className={styles.infoBlock}>
          <p>{item.title}</p>
          <p>{item.price}</p>
          <p>{item.text}</p>
        </div>
      ))}
    </div>
  );

  return (
    <section>
      <Block>
        <div className={styles.contentContainer}>
          <h3>{t('GOOD_TO_KNOW.TITLE')}</h3>
          {infoBlocks}
        </div>
      </Block>
    </section>
  );
};

export default GoodToKnowSection;
