import { PropsWithChildren, useEffect } from 'react';

import Head from 'next/head';

import { Toast } from '@components/index';
import { useSystemMessage } from '@core/messaging';
import { OnboardingData } from '@core/types';
import { useWindow } from '@lib/utils';

import { STEPS } from '../hooks/usePriceSuggestionChat';
import { useOnboardingNavigation } from '../navigation';
import { useSelectFromOnboardingStore } from '../stores/onboardingStore';
import { useOnboardingTranslation } from '../translation';
import OnboardingProgressBar from './OnboardingProgressBar';

const Layout = ({ children }: PropsWithChildren) => {
  const { hasWindow } = useWindow();
  const { message, removeMessage } = useSystemMessage();
  const t = useOnboardingTranslation();
  const { navigateToRoot, navigateToOnboardingCancelled } =
    useOnboardingNavigation();
  const { step, totalSteps, progressionStep, totalProgressionSteps } =
    useSelectFromOnboardingStore((state: OnboardingData) => state);
  const {
    PRICE_SUGGESTION_CHAT,
    PRICE_SUGGESTION_SCREEN,
    IDENTIFICATION,
    ADDRESS_CONFIRMATION_CHAT
  } = STEPS;

  const titleByStep: Record<number, string> = {
    [PRICE_SUGGESTION_CHAT]: t('PRICE_SUGGESTION_CHAT.YOUR_INFORMATION'),
    [PRICE_SUGGESTION_SCREEN]: t(
      'PRICE_SUGGESTION_SCREEN.YOUR_PRICE_SUGGESTION'
    ),
    [IDENTIFICATION]: t('IDENTIFICATION_SCREEN.ORDER'),
    [ADDRESS_CONFIRMATION_CHAT]: t('ADDRESS_CONFIRMATION_CHAT.TITLE')
  };

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const chatButton = document.getElementById('front-button-container-0');
        const chat = document.getElementById('chat-container');
        const cookiesButton = document.getElementById('ot-sdk-btn-floating');

        if (chatButton) chatButton.style.display = 'none';
        if (chat) chat.style.display = 'none';
        if (cookiesButton) cookiesButton.style.display = 'none';
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => observer.disconnect();
  }, []);

  const handleCloseOnboarding = () => {
    if (step === STEPS.PRICE_SUGGESTION_CHAT) {
      navigateToRoot();
    } else {
      navigateToOnboardingCancelled();
    }
  };

  if (!hasWindow) {
    return null;
  }

  return (
    <div className="flex h-full flex-col">
      <Head>
        <title>Sejfa</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <OnboardingProgressBar
        step={step}
        totalSteps={totalSteps}
        progressionStep={progressionStep}
        totalProgressionSteps={totalProgressionSteps}
        title={titleByStep[step] ?? ''}
        toggle={handleCloseOnboarding}
      />
      <div className="flex flex-1 flex-col overflow-hidden rounded-2xl md:rounded-[32px]">
        {children}
      </div>
      {message ? (
        <div className="fixed inset-x-0 top-0 z-50">
          <Toast type={'info'} onClose={removeMessage} message={message} />
        </div>
      ) : null}
    </div>
  );
};

export default Layout;
