'use client';

import { PropsWithChildren, useEffect } from 'react';

import Head from 'next/head';

import TopBar from '@components/RootLayout/components/TopBar/TopBar';
import { useCampaignCodeFromUrl } from '@core/discount';
import { useMessaging, useSelectFromMessageStore } from '@core/messaging';
import { useTranslation } from '@core/translation';
import { useKillswitch } from '@features/onboarding';

import { Toast } from '..';
import styles from './RootLayout.module.css';

const RootLayout = ({ children }: PropsWithChildren) => {
  useCampaignCodeFromUrl();
  const { clearMessage } = useMessaging();
  const { messages } = useSelectFromMessageStore((state) => state);

  const message = messages[0];

  const et = useTranslation('ERROR');
  const { addMessage } = useMessaging();

  const { isKillswitchEnabled } = useKillswitch();

  useEffect(() => {
    if (isKillswitchEnabled) {
      addMessage({
        message: et('KILLSWITCH_MSG')
      });
    }
  }, [isKillswitchEnabled, addMessage, et]);

  return (
    <>
      <Head>
        <title>Sejfa</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className={styles.root}>
        <TopBar />
        <div className={styles.app}>{children}</div>
        {message && (
          <Toast
            message={message.message}
            onClose={() => clearMessage(message)}
            type={message.toastType}
          />
        )}
      </div>
    </>
  );
};

export default RootLayout;
