import React, { PropsWithChildren } from 'react';

import { cn } from '@lib/utils';

import styles from './Text.module.css';

interface TextProps {
  type?:
    | 'standard'
    | 'normal-big'
    | 'normal-small'
    | 'normal-tiny'
    | 'heavy-big'
    | 'heavy-medium'
    | 'heavy-small';
  className?: string;
}

const tagMap: {
  [key in NonNullable<TextProps['type']>]: {
    element: React.ElementType;
    className?: string;
  };
} = {
  standard: { element: 'p' },
  'normal-big': { element: 'p', className: styles.normalBig },
  'normal-small': { element: 'p', className: styles.normalSmall },
  'normal-tiny': { element: 'p', className: styles.normalTiny },
  'heavy-big': { element: 'p', className: styles.heavyBig },
  'heavy-medium': { element: 'p', className: styles.heavyMedium },
  'heavy-small': { element: 'p', className: styles.heavySmall }
};

const Text = ({
  type = 'standard',
  className,
  children
}: PropsWithChildren<TextProps>) => {
  const Tag = tagMap[type].element;
  const textClassName = tagMap[type].className ?? '';

  return <Tag className={cn(textClassName, className)}>{children}</Tag>;
};

export default Text;
