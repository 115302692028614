import { cn } from '@lib/utils';

import Text from '../Text';
import styles from './ProcessStep.module.css';

export interface ProcessStepProps {
  step: number;
  title: string;
  description?: string;
  state?: 'unprocessed' | 'success' | 'error';
  children: React.ReactNode;
}

const ProcessStep = ({
  step,
  title,
  description,
  state = 'unprocessed',
  children
}: ProcessStepProps) => {
  const statesToClass = {
    unprocessed: styles.unprocessed,
    success: styles.success,
    error: styles.error
  };

  return (
    <div className={styles.processStep}>
      <div className={styles.processHeaderContainer}>
        <div className={styles.processStepHeader}>
          <Text
            className={cn(styles.stepNumber, statesToClass[state])}
            type="heavy-medium"
          >
            {step}
          </Text>
          <h5>{title}</h5>
        </div>
        {description && (
          <Text className={styles.description} type="normal-tiny">
            {description}
          </Text>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default ProcessStep;
