import Image, { ImageProps } from 'next/image';

import { cn } from '@lib/utils';

import styles from './TextAndImageSection.module.css';

type Props = {
  imageProps: Pick<ImageProps, 'src' | 'alt' | 'className'>;
  title: string;
  description: string;
  imagePosition?: 'left' | 'right';
  list?: string[];
};

const TextAndImageSection = ({
  imageProps,
  title,
  description,
  imagePosition = 'left',
  list
}: Props) => {
  const renderList = () => (
    <ul className={styles.list}>
      {!!list?.length &&
        list.map((item, index) => (
          <li key={index} className={styles.listItem}>
            {item}
          </li>
        ))}
    </ul>
  );

  const content = (
    <div className={styles.content}>
      <h3>{title}</h3>
      <p className={styles.paragraph}>{description}</p>
      {renderList()}
    </div>
  );

  const image = (
    <Image
      src={imageProps.src}
      alt={imageProps.alt}
      className={cn(styles.image, imageProps.className)}
    />
  );

  return (
    <section className={cn(styles.section)}>
      <div
        className={cn(
          styles.column1,
          imagePosition === 'right' && styles.orderLast
        )}
      >
        {image}
      </div>
      <div className={styles.column2}>{content}</div>
    </section>
  );
};

export default TextAndImageSection;
