import { useEffect } from 'react';

import { useRouter } from 'next/router';

import onboarding1Storage from '@core/storage';
import { storage as onboarding2Storage } from '@features/onboarding-2';

export const useCampaignCodeFromUrl = () => {
  const router = useRouter();

  const campaignCodeFromURL = (
    router.query['campaign'] || router.query['discount']
  )
    ?.toString()
    .toUpperCase();

  useEffect(() => {
    if (campaignCodeFromURL) {
      const { saveOnboardingStore } = onboarding2Storage();
      //TODO: Remove this once we switch to onboarding2 permanently
      const { saveCampaignCode } = onboarding1Storage();
      saveCampaignCode(campaignCodeFromURL);
      saveOnboardingStore({
        campaignCode: campaignCodeFromURL
      });
    }
  }, [campaignCodeFromURL]);

  return {
    campaignCodeFromURL
  };
};
