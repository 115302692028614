import { UseFormSetError } from 'react-hook-form';

import { CheckSimulationStatusResponse } from '@core/api';
import { OnboardingState, storage } from '@features/onboarding-2';
import { logger } from '@lib/logger';

import { INVALID_POSTAL_CODE_ERROR } from '../../quoteService';
import { OnboardingFormFields } from './OnboardingContainer';

type SuccessfulQuoteData = CheckSimulationStatusResponse['result'] &
  Omit<OnboardingState, 'residents' | 'monthlyPremium'> & {
    residents: number;
  };

export const INVALID_POSTAL_CODE_ERROR_ID = 'Invalid postal code';

export const handleSuccessfulQuote = (
  data: SuccessfulQuoteData,
  navigateToPriceSuggestion: (
    data: CheckSimulationStatusResponse['result']
  ) => void
) => {
  const { saveOnboardingStore } = storage();
  saveOnboardingStore(data);

  navigateToPriceSuggestion(data);
};

export const handleQuoteError = (
  error: unknown,
  setError: UseFormSetError<OnboardingFormFields>
) => {
  if (error instanceof Error && error.message === INVALID_POSTAL_CODE_ERROR) {
    setError('postalCode', {
      type: 'manual',
      message: INVALID_POSTAL_CODE_ERROR_ID
    });
  } else {
    setError('root', { message: 'Invalid simulation' });
    logger.error('Error simulating quote:', error);
  }
};

type InvalidPostalCodeEmailArgs = {
  sendDeniedEmail: (data: { email: string; zip: string }) => void;
  email: string;
  postalCode: string;
  setIsInvalidPostalCodeModalActive: (isActive: boolean) => void;
};

export const handleInvalidPostalCodeEmail = ({
  sendDeniedEmail,
  email,
  postalCode,
  setIsInvalidPostalCodeModalActive
}: InvalidPostalCodeEmailArgs) => {
  sendDeniedEmail({
    email,
    zip: postalCode
  });
  setIsInvalidPostalCodeModalActive(false);
};
