import { Block, MaxWidthContainer } from '@features/onboarding-2';
import { Button } from '@components/index';

import { FormDataDisplay } from '../';
import { useSummaryPageTranslation } from '../../../../translation';
import { FormData } from '../../formData';
import styles from './FormSection.module.css';
import SummaryEmailInput from './components/SummaryEmailInput';

interface FormSectionProps {
  formData?: FormData;
  setIsButtonDisabled: (isDisabled: boolean) => void;
  onChangeDetailsClick: () => void;
}

const FormSection = ({
  formData,
  setIsButtonDisabled,
  onChangeDetailsClick
}: FormSectionProps) => {
  const t = useSummaryPageTranslation();

  const handleButtonDisabled = (value: string): void => {
    setIsButtonDisabled(!value);
  };

  return (
    <Block className={styles.block}>
      <MaxWidthContainer>
        <div className={styles.blockContent}>
          <h4>{t('HEADING')}</h4>
          <FormDataDisplay formData={formData} />
          <Button
            variant="tertiary"
            hugged
            className={styles.changeDetailsButton}
            onClick={() => onChangeDetailsClick()}
          >
            {t('CHANGE_DETAILS_BUTTON')}
          </Button>
          <SummaryEmailInput onChange={handleButtonDisabled} />
        </div>
      </MaxWidthContainer>
    </Block>
  );
};

export default FormSection;
