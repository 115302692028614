import {
  CalculateQuoteResponse,
  CheckSimulationStatusResponse,
  CreateQuoteResponse
} from '../requests';

export const calculateQuoteMock: CalculateQuoteResponse = {
  result: {
    productId: '123',
    userInfo: {
      givenName: 'mock',
      firstName: 'mock',
      lastName: 'mock',
      dateOfBirth: '1992-05-16', // date-time
      emailId: 'mock',
      isEmailVerified: false,
      countryCallingCode: 'mock',
      mobileNumber: 'mock'
    },
    homeData: {
      isPostalCodeMatch: true,
      postalCode: 41463,
      address: 'Adressvägen 9 lgh 1224',
      city: 'mock',
      numberOfResidents: 2,
      policyStartDate: '2024-02-26', // date-time
      paymentPeriodId: 0,
      residentsList: [],
      paymentPeriodList: [
        {
          value: 1,
          name: 'Yearly'
        },
        {
          value: 2,
          name: 'Monthly'
        }
      ]
    },
    discountPremiumAmount: 100.0,
    discountMonthlyPremium: 10.11,
    discountCode: '“Early30%”',
    premium: 1200.05,
    monthlyPremium: 100.123,
    currency: 'SEK',
    firstPremiumAmount: 20.29292,
    campaignCode: 'test',
    preDiscountPremium: null
  },
  success: true
};
export const createQuoteMock: CreateQuoteResponse = {
  result: {
    quoteId: 123,
    quoteNumber: '123',
    isEmailVerified: false
  },
  success: true,
  error: false
};

export const simulateQuoteMock: CheckSimulationStatusResponse = {
  result: {
    isValidSimulation: true,
    monthlyPremium: 70,
    premiumAmount: 840, // Yearly price
    currency: 'SEK',
    discountPremiumAmount: 560.9,
    discountMonthlyPremium: 46.74,
    preDiscountPremium: 840
  }
};
