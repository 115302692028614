import { Text, useFooterTranslation } from '@features/onboarding-2';

import styles from './LanguageSelector.module.css';
const LanguageSelector = () => {
  const t = useFooterTranslation();
  return (
    <div className={styles.container}>
      <Text type="heavy-small">{t('LANGUAGE')}</Text>
      <Text type="heavy-big">{t('SWEDISH')}</Text>
    </div>
  );
};

export default LanguageSelector;
