import { useRouter } from 'next/router';

import ActionModal from '@components/ActionModal/ActionModal';
import { useConvertQuoteToPolicy } from '@core/api-insurance-in-a-box';

import { useSummaryPageTranslation } from '../../../../translation';

interface PayLaterModalProps {
  isOpen: boolean;
  closeModal: () => void;
  formData: {
    postalCode: number;
    address: string;
    numberOfResidents: number;
    paymentPeriodId: number;
  };
}

const PayLaterModal = ({
  isOpen,
  closeModal,
  formData
}: PayLaterModalProps) => {
  const t = useSummaryPageTranslation();
  const { push } = useRouter();

  const { mutate: createPolicy, isLoading: isConvertToQuoteLoading } =
    useConvertQuoteToPolicy({
      onSuccess: () => {
        push('/onboarding/success');
      }
    });

  return (
    isOpen && (
      <ActionModal
        isLoading={isConvertToQuoteLoading}
        onPrimaryClick={() => {
          createPolicy({
            homeData: formData
          });
        }}
        onSecondaryClick={() => closeModal()}
        title={t('PAY_LATER_MODAL.TITLE')}
        description={t('PAY_LATER_MODAL.DESCRIPTION')}
      />
    )
  );
};

export default PayLaterModal;
