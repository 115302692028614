import { forwardRef } from 'react';

import { cn } from '@lib/utils';

interface RadioButtonProps extends React.HTMLProps<HTMLInputElement> {
  id: string;
  label: string;
  className?: string;
}

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ id, label, className, ...props }, ref) => (
    <span className={cn(className)}>
      <input id={id} type="radio" ref={ref} {...props} />
      <span>{label}</span>
    </span>
  )
);

export default RadioButton;
