import { useState } from 'react';

import { useGetCampaignByCode } from '@core/api';
import { useMessaging } from '@core/messaging';
import { useTranslation } from '@core/translation';
import { storage } from '@features/onboarding-2';

type CampaignCodeState = {
  input: string | null;
  discountCode: string | null;
  campaignCode: string | null;
  discountPercentage: number | null;
};
const useCampaignCodeValidation = () => {
  const [codeState, setCodeState] = useState<CampaignCodeState>({
    input: null,
    discountCode: storage().onboarding?.discountCode ?? null,
    campaignCode: storage().onboarding?.campaignCode ?? null,
    discountPercentage: storage().onboarding?.discountPercentage ?? null
  });
  const messaging = useMessaging();
  const et = useTranslation('ERROR');

  const { isFetching } = useGetCampaignByCode(codeState.input ?? '')({
    options: {
      onError: () => {
        messaging.addMessage({
          toastType: 'error',
          message: et('INVALID_DISCOUNT_CODE')
        });
        setCodeState((prev) => ({ ...prev, input: null }));
      },
      onSuccess: (data) => {
        setCodeState({
          input: null,
          discountCode: data.discountCode,
          campaignCode: data.campaignCode,
          discountPercentage: data.discountPercentage
        });
      },
      enabled: !!codeState.input
    }
  });

  return {
    codeState,
    setCodeState,
    isFetching
  };
};

export default useCampaignCodeValidation;
