import { useState, useRef, useEffect } from 'react';

import { Icon } from '@components/index';

import useClickOutside from './hooks/useClickOutside';
import styles from './OnboardingDropdown.module.css';

type Option = {
  label: string;
  value: string;
};

type OnboardingDropdownProps = {
  onChange?: (value: Option) => void;
  options: Option[];
  label: string;
};

const OnboardingDropdown = ({
  onChange,
  options,
  label
}: OnboardingDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!onChange) return;

    onChange(selectedOption);
  }, [selectedOption, onChange]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div
      className={styles.dropdownContainer}
      ref={dropdownRef}
      onClick={toggleDropdown}
    >
      <span className={styles.label}>{label}</span>
      <div className={styles.dropdownButton}>{selectedOption.label}</div>
      <Icon
        name={isOpen ? 'arrow-up' : 'arrow-down'} // TODO: Add correct up icon
        className={styles.dropdownIcon}
        size={24}
      />
      {isOpen && (
        <div className={styles.dropdownContent}>
          {options.map((option) => (
            <div
              role="option"
              aria-selected={selectedOption.label === option.label}
              key={option.label}
              className={`${styles.dropdownOption} ${
                selectedOption.label === option.label ? styles.selected : ''
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OnboardingDropdown;
