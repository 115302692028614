import { Quote } from '@core/api';

export const calculateDiscount = (response: Quote | null) => {
  if (!response?.discountPremiumAmount || !response?.preDiscountPremium) {
    return;
  }

  const percentage =
    (response.discountPremiumAmount / response.preDiscountPremium) * 100;

  return `${percentage.toFixed(0)}%`;
};

export const calculateMonthlyPremiumBeforeDiscount = (
  response: Quote | null
) => {
  if (!response?.monthlyPremium || !response?.discountMonthlyPremium) {
    return;
  }

  return response.monthlyPremium + response.discountMonthlyPremium;
};

export const calculateYearlyPremiumBeforeDiscount = (
  response: Quote | null
) => {
  if (!response?.preDiscountPremium) {
    return;
  }

  return response.preDiscountPremium;
};
