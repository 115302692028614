import { useRouter } from 'next/router';

import { CheckSimulationStatusResponse } from '@core/api';
import { logger } from '@lib/logger';

export const useOnboardingNavigation = () => {
  const router = useRouter();

  const navigateToPriceSuggestion = (
    quoteData: CheckSimulationStatusResponse['result']
  ) => {
    const { monthlyPremium } = quoteData;
    if (!monthlyPremium) {
      logger.warn('No monthly premium available');
      return;
    }

    router.push('/onboarding/price-suggestion');
  };

  return { navigateToPriceSuggestion };
};
