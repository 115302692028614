import { useEffect, useState } from 'react';

import { useSearchParams } from 'next/navigation';

const APP_LINK_BY_ENVIRONMENT: Record<
  ENVIRONMENT,
  Record<OPERATING_SYSTEM, string>
> = {
  production: {
    Android: 'sejfa://',
    iOS: 'sejfa://',
    Other: ''
  },
  staging: {
    Android: 'sejfa://', // This should be changed to 'sejfa-staging://' when the app is ready
    iOS: 'sejfa-staging://',
    Other: ''
  },
  develop: {
    Android: 'sejfa://', // This should be changed to 'sejfa-develop://' when the app is ready
    iOS: 'sejfa-develop://',
    Other: ''
  }
};

const STORE_LINK_BY_PLATFORM: Record<OPERATING_SYSTEM, string> = {
  Android: 'https://play.google.com/store/apps/details?id=nu.sejfa.android',
  iOS: 'https://apps.apple.com/us/app/sejfa/id6449179160',
  Other: ''
};

type OPERATING_SYSTEM = 'Android' | 'iOS' | 'Other';

type ENVIRONMENT = 'production' | 'staging' | 'develop';

type UseAppLinksParams = {
  environment: ENVIRONMENT;
};

type UseAppLinksResult = {
  appLink: string;
  storeLink: string;
};

const getMobileOperatingSystem = (): OPERATING_SYSTEM => {
  if (navigator) {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }
  }
  return 'Other';
};

export const useAppLinks = ({
  environment
}: UseAppLinksParams): UseAppLinksResult => {
  const searchParams = useSearchParams();
  const [appLink, setAppLink] = useState<string>('');
  const [storeLink, setStoreLink] = useState<string>('');

  useEffect(() => {
    const os = getMobileOperatingSystem();
    const baseAppLink = APP_LINK_BY_ENVIRONMENT[environment][os];
    const storeLink = STORE_LINK_BY_PLATFORM[os];
    const appLink = `${baseAppLink}?${searchParams.toString()}`;
    setAppLink(appLink);
    setStoreLink(storeLink);
  }, []);

  return {
    appLink,
    storeLink
  };
};
