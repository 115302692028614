import { useEffect, useRef, useState } from 'react';

import { Icon } from '@components/index';
import { cn } from '@lib/utils';

import styles from './Accordion.module.css';

type AccordionContentProps = {
  subtitle?: string;
  content: string | string[];
  isExpanded: boolean;
};

const AccordionContent = ({
  subtitle,
  content,
  isExpanded
}: AccordionContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(0);

  useEffect(() => {
    if (contentRef.current) {
      const scrollHeight = contentRef.current.scrollHeight;
      setHeight(isExpanded ? scrollHeight : 0);
    }
  }, [isExpanded, content]);

  const renderList = (items: string[]) => (
    <div className={styles.contentWrapper}>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      {items.map((item, index) => (
        <div
          className={styles.listItem}
          key={`${item.substring(0, 20)}-${index}`}
        >
          <Icon name="checked" className={styles.checkIcon} />
          <p className={styles.listText}>{item}</p>
        </div>
      ))}
    </div>
  );

  const textContent = (
    <div className={styles.contentWrapper}>
      <p className={styles.contentText}>{content}</p>
    </div>
  );

  return (
    <div
      ref={contentRef}
      className={cn(styles.accordionContent, {})}
      style={{ height: height ? `${height}px` : '0px' }}
      aria-hidden={!isExpanded}
    >
      {Array.isArray(content) ? renderList(content) : textContent}
    </div>
  );
};
export default AccordionContent;
