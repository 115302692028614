import {
  usePriceSuggestionHousingTranslation,
  usePriceSuggestionLabelsTranslation
} from '@features/onboarding-2';

import { OnboardingDropdown } from '../../../../../OnboardingDropdown';
// import { Option } from '../../HomeInfoFields';

// TODO: export from somewhere
export type Option = {
  label: string;
  value: string;
};

const createHousingTypesOptions = (
  t: ReturnType<typeof usePriceSuggestionHousingTranslation>
) => [
  { label: t('APARTMENT'), value: 'apartment' },
  { label: t('VILLA_&_TOWNHOUSE'), value: 'villa' }
];

export const ResidencyTypeDropdown = ({
  onResidencyTypeChange
}: {
  onResidencyTypeChange: (type: string) => void;
}) => {
  const t = usePriceSuggestionLabelsTranslation();
  const ht = usePriceSuggestionHousingTranslation();
  const housingTypes = createHousingTypesOptions(ht);

  return (
    <OnboardingDropdown
      label={t('HOUSE_TYPE')}
      onChange={(houseType: Option) => onResidencyTypeChange(houseType.value)}
      options={housingTypes}
    />
  );
};

export default ResidencyTypeDropdown;
