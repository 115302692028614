import Link from 'next/link';

import {
  Text,
  usePriceSuggestionPageTranslation
} from '@features/onboarding-2';

import styles from './Header.module.css';

const Header = () => {
  const t = usePriceSuggestionPageTranslation();

  const renderLink = (href: string, text: string) => (
    <Link className={styles.link} href={href}>
      {text}
    </Link>
  );

  const title = <h2>{t('HEADER.TITLE')}</h2>;

  const description = (
    <Text type="normal-small" className={styles.description}>
      {t('HEADER.DESCRIPTION')}
    </Text>
  );
  const linksDescription = (
    <Text className={styles.linksDescription} type="normal-tiny">
      {t('HEADER.LINK_DESCRIPTION')}
    </Text>
  );
  const links = (
    <div className={styles.linksContainer}>
      {renderLink('/personuppgifter', t('HEADER.LINKS.PERSONAL_INFORMATION'))}
      <span>&</span>
      {renderLink('/cookies', t('HEADER.LINKS.COOKIE_POLICY'))}
    </div>
  );

  return (
    <div className={styles.container}>
      {title}
      {description}
      {linksDescription}
      {links}
    </div>
  );
};

export default Header;
