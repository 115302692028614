import { useSummaryPageTranslation } from '../../translation';
import OnboardingTextField from '../OnboardingTextField';

type Props = {
  value?: string;
  onChange: (value: string) => void;
  id?: string;
  className?: string;
};

const EmailInput = ({ id = 'email', value, onChange, className }: Props) => {
  const t = useSummaryPageTranslation(); // TODO: Move this translation to somewhere more appropriate

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <OnboardingTextField
      className={className}
      label={t('LABELS.EMAIL')}
      id={id}
      required
      onChange={handleOnChange}
      value={value}
    />
  );
};

export default EmailInput;
