import { usePriceSuggestionResidentsStates } from '../../translation';

export const initializeStates = (
  translate: ReturnType<typeof usePriceSuggestionResidentsStates>
): Record<number, string> => {
  return {
    1: translate('ONE_RESIDENT'),
    2: translate('MORE_RESIDENTS', { moreResidents: '1' }),
    3: translate('MORE_RESIDENTS', { moreResidents: '2' }),
    4: translate('MORE_RESIDENTS', { moreResidents: '3' }),
    5: translate('MORE_RESIDENTS', { moreResidents: '4' }),
    6: translate('MORE_RESIDENTS', { moreResidents: '5' }),
    7: translate('MORE_RESIDENTS', { moreResidents: '6' }),
    8: translate('MORE_RESIDENTS', { moreResidents: '7' }),
    9: translate('MORE_RESIDENTS', { moreResidents: '8' }),
    10: translate('MORE_RESIDENTS', { moreResidents: '9' })
  };
};
export const residentTextToNumber = (
  translate: ReturnType<typeof usePriceSuggestionResidentsStates>
): Record<string, number> => {
  return {
    [translate('ONE_RESIDENT')]: 1,
    [translate('MORE_RESIDENTS', { moreResidents: '1' })]: 2,
    [translate('MORE_RESIDENTS', { moreResidents: '2' })]: 3,
    [translate('MORE_RESIDENTS', { moreResidents: '3' })]: 4,
    [translate('MORE_RESIDENTS', { moreResidents: '4' })]: 5,
    [translate('MORE_RESIDENTS', { moreResidents: '5' })]: 6,
    [translate('MORE_RESIDENTS', { moreResidents: '6' })]: 7,
    [translate('MORE_RESIDENTS', { moreResidents: '7' })]: 8,
    [translate('MORE_RESIDENTS', { moreResidents: '8' })]: 9,
    [translate('MORE_RESIDENTS', { moreResidents: '9' })]: 10
  };
};

export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const MAX_RESIDENTS = 10;
export const MIN_RESIDENTS = 1;

export const reducer = (state: number, action: { type: string }) => {
  switch (action.type) {
    case INCREMENT:
      return state < MAX_RESIDENTS ? state + 1 : state;
    case DECREMENT:
      return state > MIN_RESIDENTS ? state - 1 : state;
    default:
      return state;
  }
};
