import { ActivityIndicator } from '@components/index';
import { date as dateLib } from '@lib/date';

import { QuoteResultProps } from '../';
import { Text } from '../../../../components';
import { useSummaryPageTranslation } from '../../../../translation';
import styles from './QuoteResultDisplay.module.css';

const getTodaysDate = () => {
  return dateLib.format(new Date(), 'dd/MM-yy');
};

const getLastDayOfMonth = () => {
  return dateLib.format(dateLib.lastDayOfMonth(new Date()), 'dd/MM-yy');
};

const getLastDayOfYear = () => {
  const firstDayOfNextYear = dateLib.add(dateLib.lastDayOfYear(new Date()), {
    days: 1
  });

  return dateLib.format(firstDayOfNextYear, 'dd/MM-yy');
};

const QuoteResultDisplay = ({
  price,
  unit,
  discount,
  campaignCode,
  firstPremiumAmount,
  premiumBeforeDiscount,
  isQuoteLoading,
  paymentPeriodId
}: QuoteResultProps) => {
  const t = useSummaryPageTranslation();

  const isMonthlyPayment = paymentPeriodId === '2';

  const firstPaymentStartDate = getTodaysDate();
  const firstPaymentEndDate = isMonthlyPayment
    ? getLastDayOfMonth()
    : getLastDayOfYear();

  const discountRow = (
    <div className={styles.quoteResultDisplayRow}>
      <Text type="heavy-small">{t('DISCOUNT')}</Text>
      <Text type="normal-small">{discount || t('NO_DISCOUNT')}</Text>
    </div>
  );

  const campaignCodeRow = (
    <div className={styles.quoteResultDisplayRow}>
      <Text type="normal-small">{t('CAMPAIGN_CODE')}</Text>
      <Text type="normal-small">{campaignCode}</Text>
    </div>
  );

  const firstPaymentRow = (
    <div className={styles.quoteResultDisplayRow}>
      <Text type="heavy-small">{t('FIRST_PAYMENT')}</Text>
    </div>
  );

  const firstPaymentAmountRow = (
    <div className={styles.quoteResultDisplayRow}>
      <Text type="normal-small">
        {t('REFERS_TO', {
          startDate: firstPaymentStartDate,
          endDate: firstPaymentEndDate
        })}
      </Text>
      <Text className={styles.firstPremiumAmount} type="standard">
        {isMonthlyPayment ? `${firstPremiumAmount}kr` : `${price}kr`}
      </Text>
    </div>
  );

  const continousPriceRow = (
    <div className={styles.quoteResultDisplayRow}>
      <Text type="heavy-small">{t('CONTINUOUS_PRICE')}</Text>
      <div className={styles.priceContainer}>
        {discount && (
          <p className={styles.premiumPrice}>{`${premiumBeforeDiscount}kr`}</p>
        )}
        <h4>{`${price} ${unit}`}</h4>
      </div>
    </div>
  );

  const disocuntSection = (
    <div className={styles.quoteResultDisplaySection}>
      {discountRow}
      {campaignCodeRow}
    </div>
  );

  const firstPaymentSection = (
    <div className={styles.quoteResultDisplaySection}>
      {firstPaymentRow}
      {firstPaymentAmountRow}
    </div>
  );

  if (isQuoteLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.quoteResultDisplay}>
      {campaignCode && disocuntSection}
      {firstPaymentSection}
      {continousPriceRow}
    </div>
  );
};

export default QuoteResultDisplay;
