'use client';

import { useEffect } from 'react';

import Image from 'next/image';

import BankID from '@assets/images/bank-id.webp';
import { Button } from '@components/index';
import { NextPageWithLayout } from '@pages/_app';

import useLogin from '../hooks/useLogin';
import { useLoginPageNavigation } from '../navigation';
import { useLoginPageTranslation } from '../translation';

const LoginPage: NextPageWithLayout = () => {
  const { isLoading, login, isAuthenticated } = useLogin();
  const t = useLoginPageTranslation();
  const { navigateToProfile } = useLoginPageNavigation();

  useEffect(() => {
    if (isAuthenticated) {
      navigateToProfile(); // TODO: make sure that this works
    }
  }, [isAuthenticated, navigateToProfile]);

  const instructions = [
    t('INSTRUCTIONS.FIRST'),
    t('INSTRUCTIONS.SECOND'),
    t('INSTRUCTIONS.THIRD'),
    t('INSTRUCTIONS.FOURTH')
  ];

  const handleAuthClick = () => {
    login();
  };

  return (
    <div className="grid md:grid-cols-2">
      <div className="relative hidden h-full space-y-0.5 overflow-hidden rounded-2xl md:flex md:rounded-[2rem] ">
        <Image src={BankID} alt="Login" fill className="object-cover" />
      </div>
      <div className="flex items-center justify-center space-y-0.5 rounded-2xl bg-white px-6 md:h-full md:rounded-[2rem]">
        <div className="flex flex-col justify-center gap-8">
          <h1 className="pr-10 text-[2rem] font-bold text-backgroundSecondary md:pr-0">
            {t('TITLE')}
          </h1>
          <div>
            <ol className="ml-4 list-decimal text-backgroundSecondary">
              {instructions.map((item, index) => (
                <li key={index} className="text-backgroundSecondary">
                  {item}
                </li>
              ))}
            </ol>
            <br />
            <p className="text-base font-semibold text-backgroundSecondary">
              {t('HELPER_TEXT.QUESTION')}
            </p>
            <button
              onClick={handleAuthClick}
              className="cursor-pointer text-textLinked underline"
            >
              {t('HELPER_TEXT.ANSWER')}
            </button>
          </div>
          <Button
            isLoading={isLoading}
            onClick={handleAuthClick}
            variant="secondary"
            className="h-[48px] md:w-full md:max-w-[21.5rem]"
          >
            {t('SHOW_QR_CODE')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
