import Link from 'next/link';

import { Icon } from '@components/index';
import {
  POLICY_AGREEMENT_PDF,
  PRE_SALE_INFO_PDF,
  PRODUCT_FACT_PDF
} from '@core/constants';
import { useImportantDocumentBlock } from '@core/content-management';

import { Block, Text } from '../../components';
import { usePriceSuggestionPageTranslation } from '../../translation';
import styles from './ImportantDocumentsSection.module.css';

const ImportantDocumentsSection = () => {
  const t = usePriceSuggestionPageTranslation();
  const { data: ImportantDocumentBlockData } = useImportantDocumentBlock();
  const importantBlockDocumentContent = ImportantDocumentBlockData?.fields;

  const data = [
    {
      id: 'pre-sale',
      title: importantBlockDocumentContent?.prePurchaseInformationText ?? '',
      href: PRE_SALE_INFO_PDF
    },
    {
      id: 'product-fact-sheet',
      title: importantBlockDocumentContent?.productFactSheetText ?? '',
      href: PRODUCT_FACT_PDF
    },
    {
      id: 'policy-agreement',
      title: importantBlockDocumentContent?.termsText ?? '',
      href: POLICY_AGREEMENT_PDF
    }
  ];

  const infoBlocks = (
    <div className={styles.documentBlockContainer}>
      {data.map((item, index) => (
        <Link
          key={index}
          className={styles.documentBlock}
          href={item.href}
          target="_blank"
        >
          <Icon name="open" className="self-end" />
          <Text type="heavy-big">{item.title}</Text>
        </Link>
      ))}
    </div>
  );

  return (
    <section>
      <Block>
        <div className={styles.contentContainer}>
          <h3>{t('IMPORTANT_DOCUMENTS.TITLE')}</h3>
          {infoBlocks}
        </div>
      </Block>
    </section>
  );
};

export default ImportantDocumentsSection;
