import { Button } from '@components/index';

const ModalActions = ({
  onSendEmail,
  onClose,
  confirmText,
  cancelText
}: {
  onSendEmail: () => void;
  onClose: () => void;
  confirmText: string;
  cancelText: string;
}) => (
  <div className="mt-8 flex flex-col-reverse gap-2 md:flex-row">
    <Button className="order-last" onClick={onSendEmail}>
      {confirmText}
    </Button>
    <Button variant="secondary" onClick={onClose}>
      {cancelText}
    </Button>
  </div>
);

export default ModalActions;
