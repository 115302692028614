import { FieldErrors, UseFormRegister } from 'react-hook-form';

import {
  OnboardingTextField,
  Text,
  usePriceSuggestionFormErrorsTranslation,
  usePriceSuggestionLabelsTranslation
} from '@features/onboarding-2';
import { isValidSwedishPersonalNumber } from '@lib/validation';

import { OnboardingFormFields } from '../../../OnboardingContainer';
import styles from './PersonalInfoFields.module.css';

type PersonalInfoFieldsProps = {
  register: UseFormRegister<OnboardingFormFields>;
  errors?: FieldErrors<OnboardingFormFields>;
  prefillIsLoading?: boolean;
};

const getErrorMessage = (fieldError?: { message?: string }) =>
  fieldError?.message ? { title: fieldError.message } : undefined;

const PersonalInfoFields = ({
  register,
  errors,
  prefillIsLoading
}: PersonalInfoFieldsProps) => {
  const labelsTranslations = usePriceSuggestionLabelsTranslation();
  const et = usePriceSuggestionFormErrorsTranslation();

  return (
    <>
      <OnboardingTextField
        {...register('personalNumber', {
          required: false,
          validate: (value) => {
            if (value && !isValidSwedishPersonalNumber(value)) {
              return et('INVALID_PERSONAL_NUMBER');
            }
          }
        })}
        isLoading={prefillIsLoading}
        id="personalNumber"
        type="number"
        label={labelsTranslations('PERSONAL_NUMBER')}
        errorMessageDetails={getErrorMessage(errors?.personalNumber)}
      />
      <Text type="normal-tiny" className={styles.ageDescription}>
        {labelsTranslations('AGE_DESCRIPTION')}
      </Text>
      <OnboardingTextField
        {...register('age', {
          required: {
            value: true,
            message: et('THIS_FIELD_IS_REQUIRED')
          },
          min: { value: 18, message: et('AGE_RANGE') },
          max: { value: 110, message: et('AGE_RANGE') }
        })}
        maxLength={3}
        type="number"
        id="age"
        label={labelsTranslations('AGE')}
        errorMessageDetails={getErrorMessage(errors?.age)}
      />
    </>
  );
};

export default PersonalInfoFields;
