import { useState } from 'react';

import { Icon } from '@components/index';
import { cn } from '@lib/utils';

import styles from './Accordion.module.css';
import AccordionContent from './AccordionConent';

type AccordionProps = {
  title: string;
  subtitle?: string;
  content: string | string[];
  defaultExpanded?: boolean;
  disabled?: boolean;
  className?: string;
};

const Accordion = ({
  title,
  subtitle,
  content,
  className,
  defaultExpanded = false,
  disabled = false
}: AccordionProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleToggle = () => {
    if (!disabled) {
      const newExpanded = !expanded;
      setExpanded(newExpanded);
    }
  };

  return (
    <div
      className={cn(
        styles.accordion,
        disabled && styles.disabled,
        expanded && className
      )}
    >
      <button
        className={styles.accordionButton}
        onClick={handleToggle}
        aria-expanded={expanded}
        disabled={disabled}
        type="button"
      >
        <h3 className={styles.accordionTitle}>{title}</h3>
        <Icon name={expanded ? 'remove' : 'add'} className={styles.icon} />
      </button>
      <AccordionContent
        subtitle={subtitle}
        content={content}
        isExpanded={expanded}
      />
    </div>
  );
};

export default Accordion;
