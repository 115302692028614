import { Icon } from '@components/index';

import {
  type PaymentMethodIcon,
  PaymentMethodIcon as PaymentIcon
} from '../PaymentMethodIcon';

interface DynamicPaymentIconProps {
  icon: PaymentMethodIcon['type'];
}

const DynamicPaymentIcon = ({ icon }: DynamicPaymentIconProps) => {
  return (
    <PaymentIcon>
      <Icon name={icon} className="h-4 w-8" />
    </PaymentIcon>
  );
};

export default DynamicPaymentIcon;
