import { DefaultError } from '@lib/api';

import { createMockMutation } from './mock-mutation';
import { createMockQuery } from './mock-query';
import { createMutation as createMutationOriginal } from './mutation';
import { createQuery as createQueryOriginal } from './query';
import { MutationOptions, QueryKeyDefinition, KeyFromName } from './types';

export const createQueryStore = <
  StoreQueryKey extends QueryKeyDefinition<unknown> = QueryKeyDefinition<unknown>,
  ErrorType = DefaultError
>({
  useMock
}: {
  useMock: boolean;
}) => {
  const createQuery = <KeyName extends StoreQueryKey['name'], Result>(
    queryName: KeyName,
    request: (
      ...parameters: [KeyFromName<StoreQueryKey, KeyName>['variables']]
    ) => Promise<Result>
  ) =>
    createQueryOriginal<StoreQueryKey, KeyName, Result, ErrorType>(
      queryName,
      request
    );

  const createMutation = <QueryKey extends StoreQueryKey, Result, Variables>(
    options: MutationOptions<QueryKey, Result, Variables, ErrorType>
  ) => createMutationOriginal(options);

  const createQueryWithMock = <KeyName extends StoreQueryKey['name'], Result>(
    queryName: KeyName,
    request: (
      ...parameters: [KeyFromName<StoreQueryKey, KeyName>['variables']]
    ) => Promise<Result>,
    mockResult: Result
  ) => {
    const query = createQuery(queryName, request);
    const mockQuery = createMockQuery<
      StoreQueryKey,
      KeyName,
      Result,
      ErrorType
    >(query, mockResult);

    return useMock ? mockQuery : query;
  };

  //TODO: handle void result
  const createMutationWithMock = <
    QueryKey extends StoreQueryKey,
    Result,
    Variables
  >(
    options: MutationOptions<QueryKey, Result, Variables, ErrorType>,
    mockResult: Result
  ) => {
    const mutation = createMutation(options);
    const mockMutation = createMockMutation(mutation, mockResult);

    return useMock ? mockMutation : mutation;
  };

  return {
    createQuery,
    createMutation,
    createQueryWithMock,
    createMutationWithMock
  };
};
