import { FieldErrors } from 'react-hook-form';

import { OnboardingFormFields } from './OnboardingContainer';

const PROCESS_STEP2_FIELDS = ['address', 'postalCode']; // TODO: Do we need to add housing here?

type DirtyFields = Partial<
  Readonly<Record<keyof OnboardingFormFields, boolean | undefined>>
>;

export const isFieldDirty = (
  field: string,
  dirtyFields: Record<string, boolean>
): boolean | undefined => {
  return dirtyFields[field];
};

export const hasDirtyFields = (
  dirtyFields: Record<string, boolean>
): boolean => {
  return Object.entries(dirtyFields).length > 0;
};

export const isFieldInProcessStep2 = (field: string): boolean => {
  return PROCESS_STEP2_FIELDS.includes(field);
};

export const isNoErrorInStep2 = (
  errors: FieldErrors<OnboardingFormFields>
): boolean => {
  return !Object.keys(errors).some(isFieldInProcessStep2);
};

export const validateProcessStep2 = (
  dirtyFields: DirtyFields,
  errors: FieldErrors<OnboardingFormFields>
): boolean => {
  return (
    hasDirtyFields(dirtyFields) &&
    PROCESS_STEP2_FIELDS.every((field) => isFieldDirty(field, dirtyFields)) &&
    isNoErrorInStep2(errors)
  );
};
