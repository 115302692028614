import { PropsWithChildren } from 'react';

import Head from 'next/head';

import { Footer } from '@features/onboarding-2';

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex h-full flex-col">
      <Head>
        <title>Sejfa</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
