import { useEffect } from 'react';

import { Button } from '@components/index';
import { Block, MaxWidthContainer, storage } from '@features/onboarding-2';
import { cn, useToggle } from '@lib/utils';

import { usePriceSuggestionPageTranslation } from '../../translation';
import styles from './CampaignCodeSection.module.css';
import CampaignCodeActivated from './components/CampaignCodeActivated/CampaignCodeActivated';
import CampaignCodeInput from './components/CampaignCodeInput/CampaignCodeInput';
import { useCampaignCodeValidation } from './hooks';

type Props = {
  simulateQuoteWithCode: (discountCode: string, campaignCode: string) => void;
};

const CampaignCodeSection = ({ simulateQuoteWithCode }: Props) => {
  const [isInputVisible, showInput, hideInput] = useToggle(false);
  const { codeState, setCodeState, isFetching } = useCampaignCodeValidation();
  const t = usePriceSuggestionPageTranslation();

  useEffect(() => {
    if (codeState.campaignCode) {
      simulateQuoteWithCode(
        codeState.discountCode ?? '',
        codeState.campaignCode
      );
    }
  }, [codeState.discountCode, codeState.campaignCode, simulateQuoteWithCode]);

  const handleSubmit = (code: string) => {
    setCodeState((prev) => ({ ...prev, input: code }));
  };

  const removeCodeFromStorage = () => {
    storage().saveOnboardingStore({
      discountCode: '',
      campaignCode: '',
      discountPercentage: 0
    });
  };

  const handleRemoveCode = () => {
    hideInput();
    removeCodeFromStorage();
    setCodeState({
      input: null,
      discountCode: null,
      campaignCode: null,
      discountPercentage: null
    });

    simulateQuoteWithCode('', '');
  };

  const renderContent = () => {
    if (codeState.campaignCode) {
      return (
        <CampaignCodeActivated
          code={codeState.campaignCode}
          removeCode={handleRemoveCode}
          discountPercentage={codeState.discountPercentage}
        />
      );
    }

    if (isInputVisible) {
      return (
        <div className={cn(styles.inputContainer, styles.inputContainerPadded)}>
          <CampaignCodeInput onSubmit={handleSubmit} isLoading={isFetching} />
        </div>
      );
    }

    return (
      <Button
        onClick={showInput}
        icon="add"
        variant="tertiary"
        className={styles.addCampaignCodeButton}
        iconSize={24}
      >
        {t('ADD_CAMPAIGN_CODE')}
      </Button>
    );
  };

  return (
    <Block
      className={styles.block}
      blockInnerClassName={styles.blockInnerClassName}
    >
      <MaxWidthContainer>
        <div className={styles.content}>{renderContent()}</div>
      </MaxWidthContainer>
    </Block>
  );
};

export default CampaignCodeSection;
