import { date as dateLib } from '@lib/date';

import Text from '../../../../components/Text';
import { useSummaryPageTranslation } from '../../../../translation';
import { FormData } from '../../formData';
import styles from '../../Summary.module.css';

interface FormDataRowProps {
  label: string;
  value: string | number;
}

const formatPolicyStartDate = (date: string) =>
  dateLib.formatRelative(dateLib.parseISO(date), new Date());

const FormDataRow = ({ label, value }: FormDataRowProps) => (
  <span className={styles.formDataRow}>
    <Text type="heavy-small">{label}</Text> <Text>{value}</Text>
  </span>
);

const FormDataDisplay = ({ formData }: { formData?: FormData }) => {
  const t = useSummaryPageTranslation();

  return (
    formData && (
      <div className={styles.formDataDisplay}>
        <FormDataRow
          label={t('LABELS.START_DATE')}
          value={formatPolicyStartDate(formData.policyStartDate)}
        />
        <FormDataRow label={t('LABELS.ADDRESS')} value={formData.address} />
        <FormDataRow
          label={t('LABELS.POSTAL_CODE')}
          value={formData.postalCode}
        />
        <FormDataRow
          label={t('LABELS.RESIDENTS')}
          value={formData.numberOfResidents ?? ''}
        />
      </div>
    )
  );
};

export default FormDataDisplay;
