import { ContentfulClientApi, createClient } from 'contentful';

import env from '@core/env-config';

const client: ContentfulClientApi<undefined> = createClient({
  space: env.CONTENTFUL_SPACE_ID!,
  accessToken: env.CONTENTFUL_ACCESS_TOKEN!,
  host: env.CONTENTFUL_HOST!
});

export default client;
