import Image, { StaticImageData } from 'next/image';
import { twMerge } from 'tailwind-merge';

import { Block, Button, ButtonProps } from '..';

type Props = {
  rowReverse?: boolean;
  title: string;
  desc: string;
  src?: string | StaticImageData;
  list?: string[];
  cta?: () => void;
  ctaTitle?: string;
  imageClassName?: string;
  imageContainerClassName?: string;
  textContainerClassName?: string;
  contentContainerClassName?: string;
  titleClassName?: string;
  buttonVariant?: ButtonProps['variant'];
};

const formatText = (text: string) => {
  return text.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
};

const ImageBlock = ({
  rowReverse,
  title,
  desc,
  src,
  list,
  cta,
  ctaTitle,
  imageClassName,
  imageContainerClassName,
  textContainerClassName,
  contentContainerClassName,
  titleClassName,
  buttonVariant = 'secondary'
}: Props) => {
  const imgContainerClasses = twMerge(
    'flex justify-center md:justify-end text-black md:pr-[136px]',
    rowReverse ? 'md:order-last md:justify-end md:pr-0' : '',
    imageContainerClassName
  );

  const textContainerClasses = twMerge(
    'flex flex-col items-center md:items-start justify-center text-black',
    rowReverse ? 'md:items-end' : '',
    textContainerClassName
  );

  return (
    <Block className="flex w-full justify-center space-y-12 bg-white">
      <div
        className={twMerge(
          'grid max-w-[1216px] space-y-12 bg-white px-4 py-10 md:grid-cols-2 md:space-y-0 md:py-28',
          contentContainerClassName
        )}
      >
        {src && (
          <div className={imgContainerClasses}>
            <Image
              src={src}
              alt="image-1"
              priority
              width={488}
              height={488}
              className={imageClassName}
            />
          </div>
        )}
        <div className={textContainerClasses}>
          <div className="max-w-[592px]">
            <h3
              className={twMerge('text-[40px] font-semibold', titleClassName)}
            >
              {title}
            </h3>
            {desc && (
              <p className="max-w-prose whitespace-pre-line pt-4 text-2xl font-medium md:pt-4">
                {formatText(desc)}
              </p>
            )}
            {list && (
              <ul className="center-marker list-inside list-disc p-4 marker:text-[12px] marker:font-medium marker:text-black">
                {list.map((item) => (
                  <li className="text-2xl font-medium" key={item}>
                    {item}
                  </li>
                ))}
              </ul>
            )}
            {cta && ctaTitle ? (
              <Button
                onClick={cta}
                className="mt-6 h-[72px] w-full md:mt-10 md:max-w-[343px]"
                variant={buttonVariant}
              >
                {ctaTitle}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </Block>
  );
};

export default ImageBlock;
