import React from 'react';

import { twMerge } from 'tailwind-merge';

const ModalHeader = ({
  title,
  description
}: {
  title: string;
  description: string;
}) => (
  <div>
    <h5
      className={twMerge(
        'overflow-hidden break-normal text-[40px] font-bold text-black md:text-[48px]'
      )}
    >
      {title}
    </h5>
    <p
      className={twMerge(
        'overflow-hidden break-normal text-[16px] font-semibold text-black md:text-[24px]'
      )}
    >
      {description}
    </p>
  </div>
);

export default ModalHeader;
