import { useEffect, useState } from 'react';

import { FieldErrors } from 'react-hook-form';

import { InputMessageProps } from '../../../../../components/InputMessage/InputMessage';
import { INVALID_POSTAL_CODE_ERROR_ID } from '../../OnboardingContainer/onboardingHandlers';
import { OnboardingFormFields } from '../../OnboardingContainer/OnboardingContainer';

const usePostalCodeError = (
  errors: FieldErrors<OnboardingFormFields> | undefined,
  ERROR_MESSAGES: Record<string, InputMessageProps>
) => {
  const [invalidPostalCodeMessage, setInvalidPostalCodeMessage] = useState<
    InputMessageProps | undefined
  >(undefined);

  useEffect(() => {
    if (errors?.postalCode?.message === INVALID_POSTAL_CODE_ERROR_ID) {
      setInvalidPostalCodeMessage(ERROR_MESSAGES[errors.postalCode.message]);
    } else {
      setInvalidPostalCodeMessage(undefined);
    }
  }, [errors?.postalCode, errors?.postalCode?.message, ERROR_MESSAGES]);

  return invalidPostalCodeMessage;
};

export default usePostalCodeError;
