import { useState } from 'react';

import OnboardingTextFieldSimple from '../../../../../../components/OnboardingTextFieldSimple';
import { useSummaryPageTranslation } from '../../../../../../translation';

type Props = {
  onChange: (value: string) => void;
  id?: string;
  className?: string;
};

const SummaryEmailInput = ({ id = 'email', className, onChange }: Props) => {
  const t = useSummaryPageTranslation();

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    t('NO_EMAIL')
  );

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
    setEmail(event.target.value);

    if (!event.target.value) {
      setErrorMessage(t('NO_EMAIL'));
    }

    if (event.target.value && event.target.value !== email && errorMessage) {
      setErrorMessage(undefined);
    }
  };

  return (
    <OnboardingTextFieldSimple
      value={email}
      className={className}
      label={t('LABELS.EMAIL')}
      id={id}
      required
      onChange={handleEmailChange}
      error={errorMessage}
    />
  );
};

export default SummaryEmailInput;
