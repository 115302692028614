import Layout from './components/OnboardingLayout';
import { Onboarding, simulateQuote } from './pages/Onboarding';
import PriceSuggestion from './pages/PriceSuggestion';
import Summary from './pages/Summary';
import storage, { OnboardingState } from './storage';

export * from './components';
export * from './hooks';
export * from './translation';
export {
  Onboarding,
  Layout as OnboardingLayout,
  PriceSuggestion,
  simulateQuote,
  storage,
  Summary,
  type OnboardingState
};
