import { useEffect } from 'react';

import Image, { StaticImageData } from 'next/image';
import { twMerge } from 'tailwind-merge';

import Img from '@assets/images/app_purple_background.webp';
import QRCodeAppStore from '@assets/images/qr-code-app-store.svg';
import QRCodePlayStore from '@assets/images/qr-code-google-play.svg';
import { Block, Button, Icon } from '@components/index';

import { useOpenAppTranslation } from '../translation';

type OpenAppPageProps = {
  openApp: () => void;
  openStore: () => void;
};

const renderAppQR = (
  src: string | StaticImageData,
  title: string,
  onClick: () => void
) => {
  return (
    <div className="max-h-[140px] max-w-[140px] rounded-2xl border border-greySemiLight p-2">
      <Image src={src} alt="onboarding-success-image" />
      <Button
        icon="arrow-angle-up"
        className="mt-4 h-[40px] w-full justify-start self-end bg-transparent pl-2"
        onClick={onClick}
        hugged
      >
        {title}
      </Button>
    </div>
  );
};

const renderAppButton = ({
  title,
  color,
  onClick
}: {
  title: string;
  color: 'primary' | 'secondary';
  onClick: () => void;
}) => {
  const colorClassName =
    color === 'primary'
      ? 'bg-primary active:bg-primary/40'
      : 'bg-success active:bg-success/40';
  return (
    <Block
      className={twMerge(
        'flex h-[186px] w-[50%] cursor-pointer flex-col justify-between rounded-2xl p-4 active:text-black/40',
        colorClassName
      )}
      onClick={onClick}
    >
      <Icon name="arrow-angle-up" className="self-end" />
      <div className="text-2xl font-semibold">{title}</div>
    </Block>
  );
};

const openUrl = (url: string) => window.open(url, '_blank');

const goToPlayStore = () =>
  openUrl('https://play.google.com/store/apps/details?id=nu.sejfa.android');

const goToAppStore = () =>
  openUrl('https://apps.apple.com/us/app/sejfa/id6449179160');

export const OpenAppPage = ({ openApp, openStore }: OpenAppPageProps) => {
  const t = useOpenAppTranslation();

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const chatButton = document.getElementById('front-button-container-0');
        const chat = document.getElementById('chat-container');
        const cookiesButton = document.getElementById('ot-sdk-btn-floating');

        if (chatButton) chatButton.style.display = 'none';
        if (chat) chat.style.display = 'none';
        if (cookiesButton) cookiesButton.style.display = 'none';
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="no-scrollbar flex h-full flex-col gap-0.5 md:flex-row">
      <Block className="flex-shrink md:flex-shrink-0">
        <Image
          src={Img}
          alt="open-app-image"
          className="h-full w-full object-cover"
        />
      </Block>
      <Block className="hidden items-center justify-center bg-white md:flex">
        <div className="column md:p-24 md:pt-8">
          <p className="text-[40px] font-semibold">{t('DESKTOP_HEADER')}</p>
          <p className="font-semi-bold text-xl md:my-6">
            {t('DESKTOP_DESCRIPTION')}
          </p>
          <div className="hidden space-x-9 md:flex">
            {renderAppQR(QRCodeAppStore, t('APP_STORE'), goToAppStore)}
            {renderAppQR(QRCodePlayStore, t('PLAY_STORE'), goToPlayStore)}
          </div>
        </div>
      </Block>
      <div className="flex w-full flex-row gap-0.5 md:hidden">
        {renderAppButton({
          title: t('MOBILE_DOWNLOAD'),
          color: 'secondary',
          onClick: openStore
        })}
        {renderAppButton({
          title: t('MOBILE_OPEN'),
          color: 'primary',
          onClick: openApp
        })}
      </div>
    </div>
  );
};
