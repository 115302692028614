// Context: Copied form old onboarding
import React, { PropsWithChildren } from 'react';

import { Icon } from '@components/index';

type Props = {
  isYearlyPayment: boolean;
};

export type PaymentMethodIcon = {
  type: 'visa' | 'mastercard' | 'swish' | 'apple-pay';
};

export const PaymentMethodIcon = ({ children }: PropsWithChildren) => {
  return (
    <div className="border-px flex items-center justify-center rounded border border-black bg-white p-2 text-white">
      {children}
    </div>
  );
};

const PaymentMethodIcons = ({ isYearlyPayment }: Props) => {
  return (
    <div className="flex h-[28px] gap-px">
      {isYearlyPayment && (
        <>
          <PaymentMethodIcon>
            <Icon name="apple-pay" className="h-4 w-8" />
          </PaymentMethodIcon>
          <PaymentMethodIcon>
            <Icon name="swish" className="h-4 w-8" />
          </PaymentMethodIcon>
        </>
      )}
      <PaymentMethodIcon>
        <Icon name="visa" className="h-4 w-8" />
        <Icon name="mastercard" className="h-4 w-8" />
      </PaymentMethodIcon>
    </div>
  );
};

export default PaymentMethodIcons;
