import { useCallback } from 'react';

import { simulateQuote } from '@features/onboarding-2';

import { residentTextToNumber } from '../components/OnboardingPeopleInput/reducer';
import { transformToSimulateQuoteData } from '../pages/Onboarding/components/OnboardingContainer/OnboardingContainer';
import { usePriceSuggestionResidentsStates } from '../translation';

type SimulateQuoteData = {
  personalNumber: string;
  age: string;
  address: string;
  postalCode: string;
  numberOfResidents: string;
  discountCode?: string;
  campaignCode?: string;
};

const useSimulateQuote = () => {
  const rt = usePriceSuggestionResidentsStates();

  const handleSimulateQuote = useCallback(
    async (quoteData: SimulateQuoteData) => {
      const residentStates = residentTextToNumber(rt);
      const transformedData = transformToSimulateQuoteData(
        quoteData,
        residentStates
      );

      const data = await simulateQuote(transformedData);

      return {
        ...quoteData,
        ...data,
        policyStartDate: new Date().toISOString(),
        residents: residentStates[quoteData.numberOfResidents]
      };
    },
    [rt]
  );

  return { handleSimulateQuote };
};

export default useSimulateQuote;
