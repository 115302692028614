import { NextPageWithLayout } from '@pages/_app';

import {
  CommonQuestionsSection,
  GoodToKnowSection,
  ImportantDocumentsSection,
  PriceSuggestionSection,
  WhatIsIncludedSection,
  WhenIsItSejfSection,
  WhySejfaSection
} from '../../components';
import { useOnboarding2 } from '../../hooks/useOnboarding2';
import styles from './PriceSuggestion.module.css';

const PriceSuggestion: NextPageWithLayout = () => {
  const isOnboarding2On = useOnboarding2();

  if (!isOnboarding2On) return null;

  return (
    <div className={styles.container}>
      <PriceSuggestionSection />
      <WhySejfaSection />
      <WhatIsIncludedSection />
      <GoodToKnowSection />
      <WhenIsItSejfSection />
      <CommonQuestionsSection />
      <ImportantDocumentsSection />
    </div>
  );
};

export default PriceSuggestion;
