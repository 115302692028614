import { useCallback, useState } from 'react';

import {
  normalizePersonalNumber,
  validatePersonalNumber
} from '@lib/validation';

export const useValidatePersonalNumber = (initialValue: string = '') => {
  const [rawValue, setRawValue] = useState(initialValue);
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | null>(null);

  const handleChange = useCallback((newValue: string) => {
    const validationError = validatePersonalNumber(newValue);
    const personalNumber = normalizePersonalNumber(newValue);
    setRawValue(newValue);
    setValue(personalNumber);
    setError(validationError);
  }, []);

  return {
    rawValue,
    value,
    error,
    handleChange,
    isValid: !!value && !error
  };
};
