import { useCallback, useEffect, useState } from 'react';

import { NextRouter, useRouter } from 'next/router';

import { logger } from '@lib/logger';

import { callCreatePayment } from '../../Onboarding/quoteService';

// TODO: expose this from somewhere. Redundancy in useCalculateQuoteRequest.ts
interface FormData {
  postalCode: number;
  residents: number;
  address: string;
  paymentPeriodId: string;
}

const useCreatePaymentRequest = (formData?: FormData | null) => {
  const router = useRouter();
  const [paymentRequestData, setPaymentRequestData] = useState<FormData | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    handleFormDataChange(formData, setPaymentRequestData, setIsLoading);
    // Disable exhaustive-deps rule because if we do not look at each individual field, the useEffect will cause infinite loop due to how objects work
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData?.postalCode,
    formData?.residents,
    formData?.address,
    formData?.paymentPeriodId
  ]);

  const pay = useCallback(async () => {
    setIsLoading(true);
    await handlePaymentRequest(paymentRequestData, router);
    setIsLoading(false);
  }, [paymentRequestData, router]);

  return { pay, isLoading };
};

const handleFormDataChange = (
  formData: FormData | null | undefined,
  setPaymentRequestData: React.Dispatch<React.SetStateAction<FormData | null>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!formData) {
    setPaymentRequestData(null);
    return;
  }

  setPaymentRequestData(formData);

  setIsLoading(false);
};

const handlePaymentRequest = async (
  paymentRequest: FormData | null,
  router: NextRouter
) => {
  if (!paymentRequest) {
    logger.log('Payment request data is not available');
    return;
  }

  try {
    const data = await callCreatePayment({
      homeData: {
        postalCode: paymentRequest.postalCode,
        address: paymentRequest.address,
        numberOfResidents: paymentRequest.residents,
        paymentPeriodId: Number(paymentRequest.paymentPeriodId)
      }
    });

    router.push(data.hostedPaymentPageUrl);
  } catch (error) {
    logger.error('Error calculating quote:', error);
  }
};

export default useCreatePaymentRequest;
