import { POLICY_AGREEMENT_PDF, PRE_SALE_INFO_PDF } from '@core/constants';
import { useFooterTranslation } from '@features/onboarding-2';
import { useAppLinks } from '@features/open-app';

export type FooterLinkType = {
  label: string;
  href: string;
};

type ColumnType =
  | 'insurances'
  | 'support'
  | 'our-app'
  | 'sejfa'
  | 'social'
  | 'language';

export type FooterColumnType = {
  id: ColumnType;
  title: string;
  links: FooterLinkType[];
};

const useFooterColumns = (): FooterColumnType[] => {
  const t = useFooterTranslation();
  const { storeLink } = useAppLinks({ environment: 'production' });

  const columns: FooterColumnType[] = [
    {
      id: 'insurances',
      title: t('INSURANCES'),
      links: [
        {
          label: t('HOME_INSURANCE'),
          href: PRE_SALE_INFO_PDF
        }
      ]
    },
    {
      id: 'support',
      title: t('SUPPORT'),
      links: [
        {
          label: t('TERMS_AND_CONDITIONS'),
          href: POLICY_AGREEMENT_PDF
        },
        {
          label: t('PRIVACY_POLICY'),
          href: '/din-integritet'
        },
        {
          label: t('IF_WE_DISAGREE'),
          href: '/klagomal'
        }
      ]
    },
    {
      id: 'our-app',
      title: t('OUR_APP'),
      links: [
        {
          label: t('DOWNLOAD_APP'),
          href: storeLink
        }
      ]
    },
    {
      id: 'social',
      title: t('SOCIAL'),
      links: [
        {
          label: t('INSTAGRAM'),
          href: 'https://instagram.com/sejfa.nu?igshid=MzRlODBiNWFlZA=='
        }
      ]
    },
    {
      id: 'language',
      title: t('LANGUAGE'),
      links: []
    }
  ];

  return columns;
};

export default useFooterColumns;
