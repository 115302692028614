import { PropsWithChildren } from 'react';

import { Block, MaxWidthContainer } from '@features/onboarding-2';

import { Header } from './components';
import styles from './OnboardingBlock.module.css';

const OnboardingBlock = ({ children }: PropsWithChildren) => {
  return (
    <Block className={styles.container}>
      <MaxWidthContainer>
        <div className={styles.contentWrapper}>
          <Header />
          {children}
        </div>
      </MaxWidthContainer>
    </Block>
  );
};

export default OnboardingBlock;
