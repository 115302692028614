const YEARLY_PAYMENT_ID = '1';

const isYearlyPayment = (paymentPeriodId: string) =>
  paymentPeriodId === YEARLY_PAYMENT_ID;

type GetPriceArgs = {
  monthlyPrice?: number;
  yearlyPrice?: number;
};

type GetUnitArgs = {
  monthlyText: string;
  yearlyText: string;
};

const getPrice = (
  paymentPeriodId: string,
  { monthlyPrice, yearlyPrice }: GetPriceArgs
) => (isYearlyPayment(paymentPeriodId) ? yearlyPrice : monthlyPrice);

const getUnit = (
  paymentPeriodId: string,
  { monthlyText, yearlyText }: GetUnitArgs
) => (isYearlyPayment(paymentPeriodId) ? yearlyText : monthlyText);

export { YEARLY_PAYMENT_ID, isYearlyPayment, getPrice, getUnit };
