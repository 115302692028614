import { IconButton } from '@components/index';

import { usePriceSuggestionPageTranslation } from '../../../../translation';
import styles from './CampaignCodeActivated.module.css';

type CampaignCodeActivatedProps = {
  code: string;
  removeCode: () => void;
  discountPercentage: number | null;
};

const CampaignCodeActivated = ({
  code,
  removeCode,
  discountPercentage
}: CampaignCodeActivatedProps) => {
  const t = usePriceSuggestionPageTranslation();

  const activeCode = (
    <p className={styles.title}>
      {t('DISCOUNT_CODE_ACTIVE', { discountCode: code })}
    </p>
  );

  const description = (
    <p className={styles.description}>
      {t('DISCOUNT_CODE_ACTIVE_DESCRIPTION')}
    </p>
  );

  return (
    <div className={styles.container}>
      <div>
        {activeCode}
        {!!discountPercentage && description}
      </div>
      <IconButton
        name="close"
        className={styles.iconButton}
        onClick={removeCode}
      />
    </div>
  );
};

export default CampaignCodeActivated;
