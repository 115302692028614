import { useForm, UseFormReturn } from 'react-hook-form';

import { FormData } from '../formData';

const useFormSetup = (initialFormData: FormData): UseFormReturn<FormData> => {
  return useForm<FormData>({
    values: {
      ...initialFormData,
      paymentPeriodId: '2'
    },
    mode: 'onChange'
  });
};

export default useFormSetup;
