import { Entry } from 'contentful';

import { SejfBlockContentSkeleton } from '@core/content-management';
import { cn } from '@lib/utils';

import { Accordion, Block } from '..';
import styles from './AccordionSection.module.css';

type Props = {
  title: string;
  variant: 'primary' | 'secondary';
  data:
    | Entry<SejfBlockContentSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
    | undefined;
};

const AccordionSection = ({ title, data, variant }: Props) => {
  const header = (
    <div className={styles.titleContainer}>
      <h3>{title}</h3>
    </div>
  );

  const list = (
    <div className={styles.contentContainer}>
      {data?.fields.dropdownBlocks.map((item) => (
        <Accordion
          key={item?.fields.title}
          title={item?.fields.title ?? ''}
          subtitle={item?.fields.subtitle}
          content={item?.fields.texts ?? []}
          className={cn(
            styles.accordion,
            variant === 'primary' ? styles.primary : styles.secondary
          )}
        />
      ))}
    </div>
  );

  return (
    <section className={styles.accordionSection}>
      <Block>
        <div className={styles.gridContainer}>
          {header}
          {list}
        </div>
      </Block>
    </section>
  );
};

export default AccordionSection;
