import { forwardRef } from 'react';

import { cn } from '@lib/utils';

import RadioButton from './components/RadioButton';
import PaymentIcons from './components/PaymentIcons';
import styles from './OnboardingRadioButton.module.css';
import { type PaymentMethodIcon } from './components/PaymentMethodIcon';

interface OnboardingRadioButtonProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'ref'> {
  label: string;
  id: string;
  extraPaymentIcons?: PaymentMethodIcon['type'][];
}

const OnboardingRadioButton = forwardRef<
  HTMLInputElement,
  OnboardingRadioButtonProps
>(({ id, label, extraPaymentIcons, ...props }, ref) => {
  return (
    <label className={cn(styles.container, styles.label)}>
      <RadioButton
        id={id}
        label={label}
        ref={ref}
        className={styles.radioButton}
        {...props}
      />
      <PaymentIcons icons={extraPaymentIcons} className={styles.icons} />
    </label>
  );
});

export default OnboardingRadioButton;
