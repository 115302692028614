import { useCommonQuestionsBlockContent } from '@core/content-management';

import { AccordionSection } from '../../components';
import { usePriceSuggestionPageTranslation } from '../../translation';

const CommonQuestionsSection = () => {
  const t = usePriceSuggestionPageTranslation();
  const { data } = useCommonQuestionsBlockContent();

  return (
    <AccordionSection
      title={t('COMMON_QUESTIONS.TITLE')}
      data={data}
      variant="primary"
    />
  );
};

export default CommonQuestionsSection;
