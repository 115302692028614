import { UseFormRegister } from 'react-hook-form';

import { MaxWidthContainer } from '@features/onboarding-2';

import Block from '../../../../components/Block';
import { FormData, PaymentPeriodId } from '../../formData';
import styles from './QuoteResultSection.module.css';
import { QuoteResultContent } from './components';

export interface QuoteResultProps {
  price?: number | null;
  unit: string;
  discount?: string;
  campaignCode?: string;
  firstPremiumAmount?: number;
  premiumBeforeDiscount?: number;
  isQuoteLoading: boolean;
  paymentPeriodId: PaymentPeriodId;
}
export interface QuoteResultSectionProps extends QuoteResultProps {
  isButtonDisabled?: boolean;
  handlePayment: () => Promise<void> | null;
  register: UseFormRegister<FormData>;
  renderExtraButton?: (isDisabled: boolean) => JSX.Element | null;
  isPaymentLoading: boolean;
}

const QuoteResultSection = ({
  isButtonDisabled,
  handlePayment,
  register,
  renderExtraButton,
  ...quoteResultProps
}: QuoteResultSectionProps) => (
  <Block variant="fourth" className={styles.block}>
    <MaxWidthContainer>
      <QuoteResultContent
        isButtonDisabled={isButtonDisabled}
        handlePayment={handlePayment}
        register={register}
        renderExtraButton={renderExtraButton}
        {...quoteResultProps}
      />
    </MaxWidthContainer>
  </Block>
);

export default QuoteResultSection;
