import {
  usePriceSuggestionLabelsTranslation,
  usePriceSuggestionResidencyStatuses
} from '@features/onboarding-2';

import { OnboardingDropdown } from '../../../../../OnboardingDropdown';

export type Option = {
  label: string;
  value: string;
};

const createResidencyStatuses = (
  t: ReturnType<typeof usePriceSuggestionResidencyStatuses>
) => [
  { label: t('RENTING'), value: 'renting' },
  { label: t('OWNING'), value: 'owning' }
];

export const ResidencyStatusDropdown = ({
  onStatusChange
}: {
  onStatusChange: (status: Option) => void;
}) => {
  const t = usePriceSuggestionLabelsTranslation();
  const rt = usePriceSuggestionResidencyStatuses();
  const residencyStatuses = createResidencyStatuses(rt);

  return (
    <OnboardingDropdown
      label={t('RESIDENCY_STATUS')}
      onChange={onStatusChange}
      options={residencyStatuses}
    />
  );
};

export default ResidencyStatusDropdown;
