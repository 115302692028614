import { useRouter } from 'next/router';

export const useLoginPageNavigation = () => {
  const router = useRouter();

  const navigateToLogin = () => {
    router.push('/login');
  };

  const navigateToProfile = () => {
    router.push('/profil');
  };

  return {
    navigateToProfile,
    navigateToLogin
  };
};
