import { useState } from 'react';

import { IconButton } from '@components/index';

import styles from './CampaignCodeInput.module.css';

type CampaignCodeInputProps = {
  onSubmit: (code: string) => void;
  isLoading: boolean;
};

const CampaignCodeInput = ({ onSubmit, isLoading }: CampaignCodeInputProps) => {
  const [code, setCode] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(code.trim().toUpperCase());
  };

  return (
    <form className={styles.inputContainer} onSubmit={handleSubmit}>
      <input
        type="text"
        autoFocus
        className={styles.input}
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <IconButton
        type="submit"
        name="arrow-up"
        className={styles.iconButton}
        activityIndicatorContainer={styles.activityIndicatorContainer}
        activityIndicatorClassName={styles.activityIndicator}
        isLoading={isLoading}
      />
    </form>
  );
};

export default CampaignCodeInput;
