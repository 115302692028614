import { NextPageWithLayout } from '@pages/_app';

import { useOnboarding2 } from '../../hooks/useOnboarding2';
import { OnboardingContainer } from './components/OnboardingContainer';
const Onboarding: NextPageWithLayout = () => {
  const isOnboarding2On = useOnboarding2();

  return isOnboarding2On ? <OnboardingContainer /> : null;
};

export default Onboarding;
