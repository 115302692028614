import { useWhenIsItSejfBlockContent } from '@core/content-management';

import { AccordionSection } from '../../components';
import { usePriceSuggestionPageTranslation } from '../../translation';

const WhenIsItSejfSection = () => {
  const t = usePriceSuggestionPageTranslation();
  const { data } = useWhenIsItSejfBlockContent();

  return (
    <AccordionSection
      title={t('WHEN_IS_IT_SEJF.TITLE')}
      data={data}
      variant="secondary"
    />
  );
};

export default WhenIsItSejfSection;
