import PayLaterButton from '../PayLaterButton';
import { YEARLY_PAYMENT_ID } from '../../paymentCalculations';

interface Props {
  isDisabled: boolean;
  paymentPeriodId: string;
  onClick: () => void;
}

const ConditionalPayLaterButton = ({
  isDisabled,
  paymentPeriodId,
  onClick
}: Props) => {
  if (paymentPeriodId === YEARLY_PAYMENT_ID) {
    return <PayLaterButton onClick={onClick} disabled={isDisabled} />;
  }
  return null;
};

export default ConditionalPayLaterButton;
