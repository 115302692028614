import React from 'react';

import { MessageKeys } from 'next-intl';

import { Button } from '@components/index';
import { useTranslation } from '@core/translation';

import styles from './InputMessage.module.css';
import Text from '../Text';

type Message = MessageKeys<IntlMessages, keyof IntlMessages> | string;
export interface InputMessageProps {
  title: Message;
  message?: Message;
  actionName?: Message;
  action?: () => void;
}

const isMessageKey = (
  message: Message
): message is MessageKeys<IntlMessages, keyof IntlMessages> => {
  return message.startsWith('ONBOARDING2');
};

const handleMessage = (
  message: Message,
  translate: ReturnType<typeof useTranslation>
) => (isMessageKey(message) ? translate(message) : message);

const InputMessage: React.FunctionComponent<InputMessageProps> = ({
  title,
  message,
  actionName,
  action
}) => {
  const t = useTranslation();

  return (
    <div className={styles.inputMessageBlock}>
      <div className={styles.inputMessage}>
        <div className={styles.textArea}>
          <Text type="heavy-small">{handleMessage(title, t)}</Text>
          {message && (
            <Text type="normal-tiny">{handleMessage(message, t)}</Text>
          )}
        </div>
        <ActionButton action={action} actionName={actionName} />
      </div>
    </div>
  );
};

interface ActionButtonProps {
  action?: () => void;
  actionName?: Message;
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = ({
  action,
  actionName
}) => {
  const t = useTranslation();

  if (!action || !actionName) return null;

  return (
    <Button variant="fourth" onClick={action}>
      {handleMessage(actionName, t)}
    </Button>
  );
};

export default InputMessage;
