/**
 * Validates and normalizes Swedish personal numbers.
 */

export const normalizePersonalNumber = (personalNumber: string): string => {
  const cleaned = personalNumber.replace(/[-\s]/g, ''); // Remove hyphens and whitespace
  return cleaned.length === 10
    ? (parseInt(cleaned.slice(0, 2)) > 20 ? '19' : '20') + cleaned
    : cleaned;
};

export const isValidSwedishPersonalNumber = (
  personalNumber: string
): boolean => {
  const cleaned = normalizePersonalNumber(personalNumber);

  // Ensure the format matches the expected pattern
  if (
    !/^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{4}$/.test(cleaned)
  ) {
    return false;
  }

  // Validate date
  const [year, month, day] = [
    parseInt(cleaned.slice(0, 4)),
    parseInt(cleaned.slice(4, 6)),
    parseInt(cleaned.slice(6, 8))
  ];
  const date = new Date(`${year}-${month}-${day}`);
  if (
    date.toString() === 'Invalid Date' ||
    date.getFullYear() !== year ||
    date.getMonth() + 1 !== month ||
    date.getDate() !== day
  ) {
    return false;
  }

  // Luhn algorithm for checksum
  const digits = cleaned.slice(2).split('').map(Number);
  const checksum = digits.reduce(
    (sum, digit, idx) =>
      sum +
      (idx % 2 === 0 ? (digit * 2 > 9 ? digit * 2 - 9 : digit * 2) : digit),
    0
  );
  return checksum % 10 === 0;
};

export const validatePersonalNumber = (
  personalNumber?: string
): string | null => {
  if (!personalNumber) return 'Personal number is required';
  return isValidSwedishPersonalNumber(personalNumber)
    ? null
    : 'Invalid personal number format';
};
