import { storage as storageLib } from '@lib/storage';

type StorageKey = 'onboarding';

export type OnboardingState = {
  address: string;
  postalCode: string;
  numberOfResidents: string;
  monthlyPremium: number;
  residents: number;
  policyStartDate: string;
  campaignCode?: string;
  discountCode?: string;
  discountPercentage?: number;
};

const storage = () => {
  const { getItem, setItem, clearStorage } = storageLib();

  const getSessionData = (key: StorageKey) => {
    const item = getItem(key);

    return item ? JSON.parse(item) : null;
  };

  const saveSessionData = (key: StorageKey, data: Partial<OnboardingState>) => {
    setItem(key, JSON.stringify(data));
  };

  const clearSessionStorage = () => clearStorage();

  const onboarding = getSessionData('onboarding');

  const saveOnboardingStore = (data: Partial<OnboardingState>) =>
    saveSessionData('onboarding', { ...onboarding, ...data });

  return {
    onboarding,
    setItem,
    clearSessionStorage,
    saveOnboardingStore
  };
};

export default storage;
